import React, { lazy } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { useUser } from "../../actions/user.actions";
import MainContainer from "./MainContainer";
import { Container } from "@mui/material";
import RemoveDocumentsPage from "../../pages/House/Documents/RemoveDocumentsPage";
import AnnouncementEditorPage from "../../pages/NeighborGroups/AnnouncementEditorPage";
import { useTranslation } from "react-i18next";
import { RouteType } from "../../routes";
import PropertyJoinPage from "../../pages/House/PropertyFacts/PropertyJoinPage";
import PropertyPage from "../../pages/House/PropertyFacts/PropertyPage";
import { LocalPlansSubsciptionPage, PriceSubscriptionPage } from "../../pages/Subscriptions";
import FindUnitWorkersPage from "../../pages/Workers/FindUnitWorkersPage";
import UnitWorkersPage from "../../pages/Workers/UnitWorkersPage";
import ResetPasswordPage from "../../pages/User/ResetPasswordPage";
import PostBoxPage from "../../pages/PostBox/PostBoxPage";
import ClubsSubscribeIntroPage from "../../pages/Clubs/ClubsSubscribeIntroPage";
import NewsStreamPage from "../../pages/NewsStream/NewsStreamPage";
import HouseModelPage from "../../pages/House/HouseModel/HouseModelPage";
import DocumentsPage from "../../pages/House/Documents/DocumentsPage";
import ProfilePage from "../../pages/User/ProfilePage";
import NavigationHelper from "../../helpers/navigation-helper";
import HousesPage from "../../pages/Family/HousesPage";
import FamilyPage from "../../pages/Family/FamilyPage";
import DreamHuntTermsPage from "../../pages/Contact/DreamHuntTermsPage";

const OverviewPageLazy = lazy(() => import("../../pages/Overview/OverviewPage"));
const UnsubscribeLazy = lazy(() => import("../../pages/LandingPage/Unsubscribe"));
const PartnerLoginLazy = lazy(() => import("../../pages/PartnerLogin/PartnerLoginPage"));
const AboutUsPageLazy = lazy(() => import("../../pages/AboutUs/AboutMyHousePage"));
const AboutClimatePlanPageLazy = lazy(() => import("../../pages/AboutUs/AboutOurClimatePlanPage"));
const ValidationLazy = lazy(() => import("../../pages/AboutUs/ValidationPage"));
const FamilyPageLazy = lazy(() => import("../../pages/Family/FamilyPage"));
const HousesPageLazy = lazy(() => import("../../pages/Family/HousesPage"));
const ContactPageLazy = lazy(() => import("../../pages/Contact/ContactPage"));
const DesignPollLazy = lazy(() => import("../../pages/DesignPoll/DesignPollPage"));
const NeighborGroupsPageLazy = lazy(() => import("../../pages/NeighborGroups/NeighborGroupsPage"));
const ServiceBookStartPageLazy = React.lazy(() => import("../../pages/House/ServiceBook/ServiceBookStartPage"));
const TasksPageLazy = React.lazy(() => import("../../pages/House/Tasks/TasksPage"));
const AboutPropertyLazy = React.lazy(() => import("../../pages/House/AboutProperty/about-property-page"));
const MapPageLazy = React.lazy(() => import("../../pages/House/Map/MapPage"));
const ServiceReportViewPageLazy = React.lazy(() => import("../../pages/House/ServiceBook/ServiceReportViewPage"));
const SearchUnitPageLazy = React.lazy(() => import("../../pages/Search/SearchUnitPage"));

export const propertyRoutes: RouteType[] = (() => {
  return [
    { title: "Pages.Overview.OverviewTitle", hideMenu: true, path: "overview", element: OverviewPageLazy },
    { title: "", path: "unsubscribe/:uniqueName", element: UnsubscribeLazy },
    {
      title: "Pages.AboutUs.About",
      titleOptions: {hostname: window.location.hostname},
      path: "about-us",
      element: AboutUsPageLazy,
    },
    {
      title: "Pages.AboutUs.About",
      titleOptions: { hostname: window.location.hostname },
      path: "about-our-climate",
      element: AboutClimatePlanPageLazy,
    },
    { title: "CO2Calculator.AboutClimatePlan", path: "validation", element: ValidationLazy },
    { title: "Partners login", path: "partner", element: PartnerLoginLazy, allowAnonymous: true },
    {
      title: "Pages.Contact.MainContainerTitle",
      path: "contact",
      element: ContactPageLazy,
      allowAnonymous: true,
    },
    { title: "Pages.Contact.TermsUpdated", path: "privacy", element: ContactPageLazy, allowAnonymous: true },
    {
      title: "Pages.Contact.MainContainerTitle",
      path: "privacy-dreamhunt",
      element: DreamHuntTermsPage,
      allowAnonymous: true,
    },
    { title: "Family.Family", path: "family/:id", element: FamilyPageLazy },
    { title: "Family.Family", path: "/family/:id", element: FamilyPage, allowAnonymous: true },
    { title: "HouseManagement.HousesList", path: "house-managment", element: HousesPageLazy },
    { title: "", path: "remove-documents/", element: RemoveDocumentsPage },
    { title: "", path: "remove-documents/:id", element: RemoveDocumentsPage },
    { title: "Pages.DesignPoll.DesignVoting", path: "styles", element: DesignPollLazy },
    {
      title: "UserProfile.MyProfile",
      path: "/profile",
      element: ProfilePage,
      allowAnonymous: true,
      hideMenu: NavigationHelper.IsClimateSite(),
    },
    {
      title: "NeighborGroups.NeighborGroups",
      path: "neighbor-groups/:groupId/edit/:id",
      element: AnnouncementEditorPage,
      fullScreen: true,
    },
    {
      title: "NeighborGroups.NeighborGroups",
      path: "neighbor-groups/:id",
      element: NeighborGroupsPageLazy,
      fullScreen: true,
    },
    {
      title: "NeighborGroups.NeighborGroups",
      path: "neighbor-groups",
      element: NeighborGroupsPageLazy,
      fullScreen: true,
    },
    { title: "PropertyFacts.PropertyJoinTitle", path: "/:addressURL/profile/:token/join", element: PropertyJoinPage, allowAnonymous: true },
    { title: "Base.MapFilter.MyHouse", path: "/:addressURL/profile", element: PropertyPage },
    { title: "Base.MapFilter.MyHouse", path: "/:addressURL/reset-password", element: PropertyPage, allowAnonymous: true },
    {
      title: "",
      path: "/:addressURL/servicebook",
      element: ServiceBookStartPageLazy,
      allowAnonymous: true,
      fullScreen: true,
    },
    {
      title: "ServiceBook.Tasks.YourHomeTasks",
      path: "/:addressURL/tasks",
      element: TasksPageLazy,
      allowAnonymous: true,
    },
    {
      title: "Pages.HouseModel.3DMmodelYourHomeTitle",
      path: "/:addressURL/model",
      element: HouseModelPage,
      fullScreen: true,
    },
    { title: "Pages.House.MyHomeAlarm", path: "/aboutproperty", element: AboutPropertyLazy },
    { title: "Mine boligdokumenter", path: "/documents", element: DocumentsPage },
    { title: "", path: "/servicereport/:id", element: ServiceReportViewPageLazy, fullScreen: true },
    { title: "Pages.Map.MyNeighborhoodTitle", path: "/map", element: MapPageLazy, fullScreen: true },
    { title: "NewsStream.MyLocalNews", path: "/news", element: NewsStreamPage },
    { title: "NewsStream.MyLocalNews", path: "/favorite-news", element: NewsStreamPage, allowAnonymous: true },
    {
      title: "",
      path: "/clubs-subscribe",
      element: ClubsSubscribeIntroPage,
      allowAnonymous: true,
    },
    { title: "", path: "/postbox", element: PostBoxPage, allowAnonymous: true },
    { title: "", path: "/reset-password", element: ResetPasswordPage, allowAnonymous: true },
    { title: "ServiceBook.Workers.MyCraftsmen", path: "/workers", element: UnitWorkersPage, allowAnonymous: true },
    {
      title: "ServiceBook.Workers.FindCraftsmen",
      path: "/workers/find",
      element: FindUnitWorkersPage,
      allowAnonymous: true,
    },
    {
      title: "Pages.Clubs.MyhouseClub",
      titleOptions: { hostname: window.location.hostname },
      path: "/subscriptions/houseprice",
      element: PriceSubscriptionPage,
    },
    {
      title: "Pages.Clubs.MyhouseClub",
      titleOptions: { hostname: window.location.hostname },
      path: "/subscriptions/localplans",
      element: LocalPlansSubsciptionPage,
    },
    { title: "SearchResult.Search", path: "/search", element: SearchUnitPageLazy },
    { title: "SearchResult.Search", path: "/search/move-in", element: SearchUnitPageLazy },
    { title: "SearchResult.Search", path: "/search/bank", element: SearchUnitPageLazy },
    { title: "SearchResult.Search", path: "/:addressURL/search", element: SearchUnitPageLazy },
    { title: "HouseManagement.HousesList", path: "/house-management", element: HousesPage, allowAnonymous: true },
  ];
})();

const PropertyLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const [{ uiMode }, userActions] = useUser();
  const { t } = useTranslation("translation");

  React.useEffect(() => {
    if (uiMode !== "unit") {
      userActions.setUiMode("unit");
    }
  }, [pathname]);

  const getPageTitleFromUrl = (pathname: string) => {
    const currentRoute = propertyRoutes.find((r) => {
      if (matchPath({ path: "/" + r.path }, pathname)) {
        return true;
      }

      return false;
    });

    return currentRoute?.title ? t(currentRoute.title, currentRoute.titleOptions) + "" : "";
  };

  const hideMenu = () => {
    const currentRoute = propertyRoutes.find((r: RouteType) => {
      if (matchPath({ path: r.path }, pathname)) {
        return true;
      }

      return false;
    });

    return currentRoute?.hideMenu ? true : false;
  };

  const showFullScreen = () => {
    const currentRoute = propertyRoutes.find((r: RouteType) => {
      if (matchPath({ path: r.path }, pathname)) {
        return true;
      }
      return false;
    });

    return currentRoute?.fullScreen ? true : false;
  };

  return (
    <MainContainer hideMenu={hideMenu()} title={getPageTitleFromUrl(pathname)}>
      {showFullScreen() ? (
        <Outlet />
      ) : (
        <Container maxWidth="md">
          <Outlet />
        </Container>
      )}
    </MainContainer>
  );
};

export default PropertyLayout;
