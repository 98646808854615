import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CompanyAutocompleteItem, CreateCustomWorker, CraftsmanType } from "../../../definitions/model/Worker";
import { TextFieldConnector, ControllerConnector } from "../../../components/Base/FormConnector";
import { SearchResult } from "../../../definitions/model/SearchResult";
import { LoadingButton } from "@mui/lab";
import { PhoneField } from "../../../components/Base/FormattedFields";
import { matchIsValidTel } from "mui-tel-input";
import { PhoneRegexp } from "../../../services/validation.service";

type WorkerDialogProps = {
  open: boolean;
  handleClose: () => void;
  createWorker: (worker: CreateCustomWorker) => Promise<void>;
  getCompanies: (name: string) => Promise<SearchResult<CompanyAutocompleteItem>>;
  actionError?: string;
};

type WorkerDialogFormData = {
  Name: string;
  Email: string;
  PhoneNumber: string;
  CVR: string;
  Types: number[];
  Address: string;
  PostCode: number;
  City: string;
  WebSite: string;
  AuthNumber: number;
  Comment: string;
  ContactPerson: string;
  Mobile: string;
};

const StyledSelect = styled(Select)({
  marginTop: 4,
});
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: 13,
  "&.Mui-selected, &.Mui-selected:hover": {
    border: `${theme.palette.secondary.main} solid 1px`,
  },
}));

const WorkerCreateDialog = (props: WorkerDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const methods = useForm<WorkerDialogFormData>({
    defaultValues: {
      PhoneNumber: "+45",
      Mobile: "",
      Types: [],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = methods;

  const onSubmit = async (data: WorkerDialogFormData) => {
    setLoading(true);
    const formattedPhone = (phone: string): string => {
      return phone.split(" ").reduce((acc, item, index) => {
        if (!index) {
          return acc + item + " ";
        }
        return acc + item;
      }, "");
    };

    data.PhoneNumber = formattedPhone(data.PhoneNumber);
    if (data.Mobile) {
      data.Mobile = formattedPhone(data.Mobile);
    }

    //need to handle errors from server and show them in the form
    const errorsHandler = (error: string): boolean => {
      if (error.includes(data.Name)) {
        setError("Name", { type: "manual", message: error });
        return true;
      }

      if (error.includes(data.Email)) {
        setError("Email", { type: "manual", message: error });
        return true;
      }

      if (error.includes(data.CVR)) {
        setError("CVR", { type: "manual", message: error });
        return true;
      }
      return false;
    };

    props
      .createWorker(data)
      .then(() => {
        props.handleClose();
        navigate("/workers");
      })
      .catch((e) => {
        if (!errorsHandler(e.statusText)) {
          props.handleClose();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={props.open} onClose={() => !loading && props.handleClose()} fullWidth={true} maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t("ServiceBook.Workers.CreateWorker")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldConnector register={register("Name", { required: true })}>
                  <TextField
                    autoFocus
                    margin="none"
                    label={t("ServiceBook.Workers.CompanyOrName")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.Name}
                    sx={{ marginTop: "5px" }}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12}>
                <TextFieldConnector register={register("Email", { required: true })}>
                  <TextField
                    margin="none"
                    label={t("ServiceBook.Workers.EmailCapsFirst")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.Email}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12}>
                <ControllerConnector
                  name="PhoneNumber"
                  rules={{
                    pattern: PhoneRegexp,
                    required: true,
                    maxLength: 16,
                    validate: (e: any) => matchIsValidTel(e),
                  }}
                >
                  <PhoneField
                    variant="outlined"
                    fullWidth
                    label={t("Base.ContactMeBox.PhoneNumber")}
                    error={!!errors.PhoneNumber}
                    type="phone"
                  />
                </ControllerConnector>
              </Grid>
              <Grid item xs={12}>
                <TextFieldConnector
                  register={register("CVR", {
                    required: true,
                    pattern: /^\d{8}$/,
                  })}
                >
                  <TextField margin="none" label="CVR" variant="outlined" fullWidth error={!!errors.CVR} />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>{t("ServiceBook.Workers.WorkerType")}</InputLabel>
                      <StyledSelect
                        {...field}
                        fullWidth
                        variant="outlined"
                        margin="none"
                        multiple
                        error={!!errors.Types}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          PaperProps: {
                            variant: "outlined",
                            square: true,
                            sx: {
                              maxHeight: "200px",
                            },
                          },
                          MenuListProps: {
                            sx: {
                              padding: 0,
                            },
                          },
                        }}
                      >
                        {CraftsmanType.map((type) => ({
                          key: type.key,
                          value: t(type.value),
                        }))
                          .sort((a, b) => {
                            if (a.key == 100) return 1;
                            return a.value > b.value ? 1 : -1;
                          })
                          .map((type) => (
                            <StyledMenuItem key={type.key} value={type.key}>
                              {type.value}
                            </StyledMenuItem>
                          ))}
                      </StyledSelect>
                    </FormControl>
                  )}
                  name="Types"
                  control={control}
                  rules={{ validate: (value) => !!value.length }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector register={register("Address")}>
                  <TextField
                    margin="none"
                    label={t("Base.Popovers.Address")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.Address}
                  />
                </TextFieldConnector>
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector
                  register={register("PostCode", {
                    pattern: /^\d{4}$/,
                  })}
                >
                  <TextField
                    margin="none"
                    label={t("Base.Popovers.ZipCode")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.PostCode}
                  />
                </TextFieldConnector>
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector register={register("City")}>
                  <TextField
                    margin="none"
                    label={t("ServiceBook.Workers.City")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.City}
                  />
                </TextFieldConnector>
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector register={register("WebSite")}>
                  <TextField
                    margin="none"
                    label={t("Base.Popovers.Website")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.WebSite}
                  />
                </TextFieldConnector>
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector register={register("AuthNumber")}>
                  <TextField
                    margin="none"
                    label={t("ServiceBook.Workers.AuthNumber")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.AuthNumber}
                  />
                </TextFieldConnector>
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector register={register("ContactPerson")}>
                  <TextField
                    margin="none"
                    label={t("ServiceBook.Workers.ContactPerson")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.ContactPerson}
                  />
                </TextFieldConnector>
              </Grid>

              <Grid item xs={12}>
                <ControllerConnector
                  name="Mobile"
                  rules={{
                    pattern: PhoneRegexp,
                    maxLength: 16,
                    validate: (e: any) => {
                      if (e === "") return true;
                      return matchIsValidTel(e);
                    },
                  }}
                >
                  <PhoneField
                    variant="outlined"
                    fullWidth
                    label={t("ServiceBook.Workers.Mobile")}
                    error={!!errors.Mobile}
                    type="phone"
                  />
                </ControllerConnector>
              </Grid>

              <Grid item xs={12}>
                <TextFieldConnector register={register("Comment")}>
                  <TextField
                    margin="none"
                    label={t("Base.Dialog.Comment")}
                    variant="outlined"
                    multiline
                    rows={2}
                    fullWidth
                    error={!!errors.Comment}
                  />
                </TextFieldConnector>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary" disabled={loading}>
              {t("General.Buttons.Undo")}
            </Button>
            <LoadingButton type="submit" color="primary" loading={loading}>
              {t("General.Buttons.Create")}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default WorkerCreateDialog;
