import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ArrowBack, ArrowDropDown, Search } from "@mui/icons-material";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CraftsmanType } from "../../definitions/model/Worker";
import { MCard } from "../../components/Base";
import WorkerComponent from "./components/worker-component";
import WorkerCreateDialog from "./components/worker-create-dialog";
import WorkerConfirmDialog from "./components/worker-confirm-dialog";
import DetailsSkeleton from "../../components/Base/DetailsSkeleton";
import { useDefaultReducer } from "../../helpers/hooks";
import { styled } from "@mui/material/styles";
import { useUser } from "../../actions";
import SmsConsentDialog from "./components/SmsConsentDialog";
import AskForPhoneNumberDialog from "./components/AskForPhoneNumberDialog";
import { useServicebookWorker } from "./workers.actions";
import WorkerHelper from "../../helpers/worker-helper";

const SearchField = styled(TextField)(() => ({
  marginTop: 15,
  "& input": {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
}));

type FindUnitWorkersPageState = {
  workerType: number;
  searchString: string;
  isCreateOpened: boolean;
  isConfirmOpened: boolean;
  isWorkerDialogShown: boolean;
  isCompanyDialogShown: boolean;
  contactMeOpen: boolean;
  smsConsentOpen: boolean;
  smsAsked: boolean;
  workerToLink: string | undefined;
  linking: boolean;
  totalWorkers: number;
};

const initialState: FindUnitWorkersPageState = {
  workerType: 0,
  searchString: "",
  isCreateOpened: false,
  isConfirmOpened: false,
  isWorkerDialogShown: false,
  isCompanyDialogShown: false,
  contactMeOpen: false,
  smsConsentOpen: false,
  smsAsked: false,
  workerToLink: undefined,
  linking: false,
  totalWorkers: 0,
};

const FindUnitWorkersPage = () => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const pageAmount = 10;
  const [
    { loading, workers, unit, unitWorkers, unitWorkersLoaded, error },
    { getWorkers, getUnitWorkers, createWorker, getCompanies, linkWorkerToUnit, unlinkWorkerFromUnit, rateUnitWorker },
  ] = useServicebookWorker();
  const { t } = useTranslation();
  const stateRef = useRef(state);

  const [{ user, settings }, { getUserSettings, updateNotificationSettigns, updateUserProfile }] = useUser();

  useEffect(() => {
    const getAsync = async () => {
      if (unit) {
        await getWorkers(
          {
            SearchString: state.searchString,
            FilterByType: state.workerType !== 0,
            PostCode: unit.Address.PostalCode,
            CommuneCode: [unit.Address.Commune.CommuneNumber],
            Types: state.workerType !== 0 ? [state.workerType] : WorkerHelper.GetWorkerTypes(state.searchString),
          },
          0,
          pageAmount
        );
      }
    };
    getAsync();
  }, [unit, state.workerType, state.searchString]);

  useEffect(() => {
    const getAsync = async () => {
      if (!settings) {
        await getUserSettings();
      }
    };

    getAsync();
  }, []);

  useEffect(() => {
    if (state.isWorkerDialogShown) {
      return;
    }
    if (state.searchString.length > 2 && !workers.length) {
      openConfirmDialog();
      dispatch({ type: "isWorkerDialogShown", payload: true });
    }
    dispatch({ type: "totalWorkers", payload: workers.length });
  }, [state.searchString, workers.length]);

  useEffect(() => {
    const getAsync = async () => {
      if (unit && !unitWorkers.length && !unitWorkersLoaded) {
        await getUnitWorkers(unit.Id);
      }
    };
    getAsync();
  }, [unit, unitWorkers, unitWorkersLoaded]);

  const handleScroll = async () => {
    const { scrollTop, offsetHeight } = document.documentElement;
    if (window.innerHeight + scrollTop > offsetHeight - 300 && !loading && unit) {
      await getWorkers(
        {
          SearchString: stateRef.current?.searchString || "",
          FilterByType: state.workerType !== 0,
          PostCode: unit.Address.PostalCode,
          CommuneCode: [unit.Address.Commune.CommuneNumber],
          Types:
            stateRef.current?.workerType !== 0
              ? [stateRef.current?.workerType]
              : WorkerHelper.GetWorkerTypes(stateRef.current?.searchString || ""),
        },
        stateRef.current?.totalWorkers,
        pageAmount
      );
    }
  };
  useEffect(() => {
    const handler = _.debounce(handleScroll, 300);
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  useEffect(() => {
    stateRef.current = state;
  }, [state.workerType, state.searchString, state.totalWorkers]);

  const WorkerTypeChange = (event: SelectChangeEvent<number>) => {
    dispatch({ type: "workerType", payload: event.target.value as number });
  };

  const searchStringChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const toggleCreateDialog = () => {
    dispatch({ type: "isCreateOpened", payload: !state.isCreateOpened });
  };

  const openConfirmDialog = () => {
    dispatch({ type: "isConfirmOpened", payload: true });
  };

  const closeConfirmDialog = () => {
    dispatch({ type: "isConfirmOpened", payload: !state.isConfirmOpened });
  };

  const linkWorker = async (workerId: string) => {
    try {
      if (unit) {
        return await linkWorkerToUnit(workerId, unit.Id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: "workerToLink", payload: undefined });
    }
  };

  const checkSmsConsent = async (workerId: string) => {
    dispatch({ type: "workerToLink", payload: workerId });
    if (!settings?.ReceiveCalls && !state.smsAsked) {
      dispatch({ type: "smsConsentOpen", payload: true });
      return;
    }

    if (settings?.ReceiveCalls && user && !user.Phone) {
      dispatch({ type: "contactMeOpen", payload: true });
      return;
    }

    try {
      dispatch({ type: "linking", payload: true });
      await linkWorker(workerId);
    } finally {
      dispatch({ type: "linking", payload: false });
    }
  };

  const unlinkWorker = async (workerId: string) => {
    try {
      if (unit) {
        return await unlinkWorkerFromUnit(workerId, unit.Id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rate = async (wokrerId: string, rate: number, comment: string | null) => {
    try {
      if (unit) {
        return await rateUnitWorker(wokrerId, unit.Id, rate, comment);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeSmsConsentDialog = async (confirmed?: boolean) => {
    dispatch({ type: "smsConsentOpen", payload: false });
    dispatch({ type: "smsAsked", payload: true });
    if (confirmed) {
      try {
        await updateNotificationSettigns(
          null,
          settings?.ReceiveNotifications || false,
          true,
          settings?.ReceiveFacebookNotifications || false
        );
      } catch (error) {
        console.error(error);
      }

      if (!user?.Phone) {
        dispatch({ type: "contactMeOpen", payload: true });
        return;
      }
    }

    if (state.workerToLink) {
      try {
        dispatch({ type: "linking", payload: true });
        await linkWorker(state.workerToLink);
      } finally {
        dispatch({ type: "linking", payload: false });
      }
    }
  };

  const updatePhone = async (phone?: string) => {
    try {
      if (phone) {
        await updateUserProfile({
          Email: user?.Email || "",
          Language: user?.Language || "da",
          Name: user?.Name || "",
          Phone: phone || "",
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: "contactMeOpen", payload: false });
      dispatch({ type: "smsAsked", payload: true });

      //try to link a worker whether the customer confirmed a phone number or not
      if (state.workerToLink) {
        try {
          dispatch({ type: "linking", payload: true });
          await linkWorker(state.workerToLink);
        } finally {
          dispatch({ type: "linking", payload: false });
        }
      }
    }
  };

  return (
    <>
        <Button component={Link} to={"/workers"} type="submit" color="secondary">
          <ArrowBack fontSize="small" />
          {t("General.Buttons.Back")}
        </Button>
        <Button color="secondary" style={{ marginLeft: 20 }} onClick={toggleCreateDialog}>
          {t("ServiceBook.Workers.CreateWorker")}
        </Button>

        <WorkerCreateDialog
          open={state.isCreateOpened}
          handleClose={toggleCreateDialog}
          createWorker={createWorker}
          getCompanies={getCompanies}
          actionError={error || ""}
        />
        <WorkerConfirmDialog
          open={state.isConfirmOpened}
          handleClose={closeConfirmDialog}
          openCreateDialog={toggleCreateDialog}
        />

        <Grid>
          <SearchField
            label={t("ServiceBook.Workers.FindWorker")}
            variant="outlined"
            fullWidth
            value={state.searchString}
            onChange={searchStringChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid>
          <FormControl variant="outlined" fullWidth sx={{ margin: "10px 0" }} size="small">
            <InputLabel id="worker-type-label">{t("ServiceBook.Workers.FilterWorker")}</InputLabel>
            <Select
              labelId="worker-type-label"
              variant="outlined"
              sx={{
                paddingTop: "2px",
                paddingBottom: "1px",
              }}
              label={t("ServiceBook.Workers.FilterWorker")}
              value={state.workerType}
              onChange={WorkerTypeChange}
              IconComponent={(props) => <ArrowDropDown fontSize="large" {...props} />}
            >
              <StyledMenuItem value={0}>
                <em>{t("ServiceBook.ReportList.No")}</em>
              </StyledMenuItem>
              {CraftsmanType.filter((type) => type.key !== 200)
                .map((type) => ({
                  key: type.key,
                  value: t(type.value),
                }))
                .sort((a, b) => {
                  if (a.key == 100) return 1;
                  return a.value > b.value ? 1 : -1;
                })
                .map((type) => (
                  <StyledMenuItem key={type.key} value={type.key}>
                    {type.value}
                  </StyledMenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {loading && !workers.length ? (
          <List disablePadding>
            {new Array(10).fill(0).map((_, idx) => (
              <DetailsSkeleton key={idx} />
            ))}
          </List>
        ) : workers.length ? (
          <MCard type="main" title={t("ServiceBook.Workers.FindCraftsmen")}>
            <List disablePadding>
              {workers.map((w, i) => (
                <WorkerComponent
                  key={"worker-" + w.Id + i}
                  worker={w}
                  linked={unitWorkers.some((c) => c.Id === w.Id)}
                  showControls={true}
                  error={error}
                  linkWorkerToUnit={checkSmsConsent}
                  unlinkWorkerFromUnit={unlinkWorker}
                  rateWorker={rate}
                  linking={w.Id === state.workerToLink && state.linking}
                />
              ))}
            </List>
          </MCard>
        ) : (
          state.isWorkerDialogShown &&
          !!state.searchString && <Box mt={2.25}>{t("ServiceBook.Workers.WorkerNotFound")}</Box>
        )}
        <Grid container justifyContent="center">
          <Fade in={loading}>
            <Box my={2.25}>
              <CircularProgress size={50} />
            </Box>
          </Fade>
        </Grid>
      <SmsConsentDialog open={state.smsConsentOpen} handleClose={closeSmsConsentDialog} />
      <AskForPhoneNumberDialog open={state.contactMeOpen} handleClose={updatePhone} user={user} />
    </>
  );
};

export default FindUnitWorkersPage;

const StyledMenuItem = styled(MenuItem)(() => ({
  paddingLeft: 10,
  paddingRight: 10,
}));
