import React, { useEffect, useState } from "react";
import { Chip, TextFieldProps, TextField, Autocomplete, AutocompleteRenderInputParams } from "@mui/material";
import { WidgetPage } from "../../constants/enums";

export type TransferListItem = {
  name: string;
  pageValue: WidgetPage | string;
  subPageValue: string;
};

type SingleTransferListProps<T> = {
  options: Array<T>;
  selected: T;
  fieldLabel: string;
  placeholder: string;
  disabled?: boolean;
  error?: boolean;
  onChanges: (rightSide: T | null) => void;
  getOptionLabel: (o: T | null) => string;
  getOptionSelected: (selected: T, option: T) => boolean;
};

function SingleTransferList<T>(props: SingleTransferListProps<T>): JSX.Element {
  const [selectedItem, setSelectedItem] = useState<T | null>(props.selected);

  useEffect(() => {
    setSelectedItem(props.selected);
  }, [props.selected]);

  useEffect(() => {
    props.onChanges(selectedItem);
  }, [selectedItem]);

  return (
    <Autocomplete
      disabled={props.disabled}
      value={selectedItem}
      onChange={(event, newValue) => setSelectedItem(newValue)}
      options={props.options}
      getOptionLabel={(o) => props.getOptionLabel(o)}
      isOptionEqualToValue={(o, value) => {
        return selectedItem != null;
      }}
      renderTags={(tagValue, getTagProps) => (
        <Chip label={props.getOptionLabel(selectedItem)} {...getTagProps({ index: 0 })} key={`chip_key_${0}`} />
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...(params as TextFieldProps)}
          label={props.fieldLabel}
          variant="outlined"
          placeholder={props.placeholder}
          error={props.error}
        />
      )}
    ></Autocomplete>
  );
}

export default SingleTransferList;
