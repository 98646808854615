import React from "react";
import { Control, FieldErrors, useFieldArray, UseFormRegister } from "react-hook-form";
import { EditTenderFormData } from "./EditTenderForm";
import { Button, Card, CardActions, CardContent, Grid, IconButton, TextField } from "@mui/material";
import { NIL, v4 as uuidv4 } from "uuid";
import { Trans, useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";

type TenderSectionItemProps = {
  control: Control<EditTenderFormData, any>;
  register: UseFormRegister<EditTenderFormData>;
  errors: FieldErrors<EditTenderFormData>;
  sectionIndex: number;
};

const TenderSectionItems = ({ control, register, errors, sectionIndex }: TenderSectionItemProps) => {
  const { t } = useTranslation("translation");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `Sections.${sectionIndex}.Items`,
  });

  return (
    <Card>
      <CardContent>
        {fields.map((item, itemIndex) => (
          <Card key={item.id} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={9}>
                  <TextFieldConnector
                    register={register(`Sections.${sectionIndex}.Items.${itemIndex}.Name`, { required: true })}
                  >
                    <TextField
                      label={t("ServiceBook.Tender.Name")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.Name}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={1} alignContent="flex-end">
                  <IconButton onClick={() => remove(itemIndex)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.Description`, { required: true })}
                >
                  <TextField
                    label={t("ServiceBook.Tender.Description")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    rows={2}
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.Description}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.Quantity`, { required: true })}
                >
                  <TextField
                    label={t("ServiceBook.Tender.Quantity")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.Quantity}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.PricePerItem`, { required: true })}
                >
                  <TextField
                    label={t("ServiceBook.Tender.PricePerItem")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.PricePerItem}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.CO2EmissionPerItem`, {
                    required: true,
                  })}
                >
                  <TextField
                    label={<Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2EmissionPerItem" />}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.CO2EmissionPerItem}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.WorkHours`, { required: true })}
                >
                  <TextField
                    label={t("ServiceBook.Tender.WorkHours")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.WorkHours}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.WorkHourPrice`, { required: true })}
                >
                  <TextField
                    label={t("ServiceBook.Tender.WorkHourPrice")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.WorkHourPrice}
                  />
                </TextFieldConnector>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            append({
              Id: uuidv4(),
              Name: "",
              Description: "",
              MaterialId: NIL,
              PricePerItem: 0,
              CO2EmissionPerItem: 0,
              Quantity: 0,
              WorkHours: 0,
              WorkHourPrice: 0,
            });
          }}
        >
          {t("ServiceBook.Tender.AddItem")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default TenderSectionItems;
