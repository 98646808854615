import React from "react";
import { Grid, Typography, Button, styled } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ClimateImage from "../../../../assets/img/seashore.jpg";
import { useUser } from "../../../../actions";
import ClimateIcons from "../../../../assets/img/icons-row.png";
import HandPlantIcon from "../../../../assets/img/handIcon.png";

const ClimateIconsTop = styled("img")(({ theme }) => ({
  maxWidth: 200,
  [theme.breakpoints.down("sm")]: {
    maxWidth: 150,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  paddingBottom: 0,
}));

const HandPlant = styled("img")(() => ({
  width: 30,
  position: "relative",
  top: 10,
}));
const Banner = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${ClimateImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  color: theme.palette.background.paper,
  flex: 1,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    minHeight: "calc(100vh - 50px)",
    width: "calc(100% - 32px)",
    marginRight: 16,
    marginLeft: 16,
  },
}));

const CommercialWelcomePart = (): JSX.Element => {
  const { t } = useTranslation("translation");
  const [{ user }, { openLoginDialog, openRegisterDialog, setAuthDestination, logout }] = useUser();

  React.useEffect(() => {
    setAuthDestination(false, "/co2calculator", 0);
  }, []);

  const loginClick = () => {
    openLoginDialog(false);
  };

  const registerClick = () => {
    openRegisterDialog();
  };

  const changeAccount = () => {
    try {
      logout();
      loginClick();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Banner item container justifyContent={"center"}>
      <Grid
        item
        container
        xs={11}
        sm={8}
        py={2}
        md={6}
        lg={4}
        sx={{ flexWrap: "nowrap", flex: 1 }}
        justifyContent={"center"}
      >
        <Grid
          item
          pt={4}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flex: 1 }}
        >
          <Grid item container direction="column" alignItems="center" mb={2}>
          <ClimateIconsTop alt="" src={ClimateIcons} />
            <StyledTypography mt={2} variant="body2">
            <Trans components={{ b: <b /> }} i18nKey="CO2Calculator.StartPage.Co2Users" />
            </StyledTypography>
          </Grid>
          <Grid item my={2}>
            <StyledTypography align="center" variant="h1" pt={1}>
              <Trans components={{ sub: <sub /> }} i18nKey="CO2Calculator.StartPage.Co2Footprint" />
              <HandPlant src={HandPlantIcon} alt={""} />
            </StyledTypography>
            <StyledTypography align="center" variant="h1">
              {t("CO2Calculator.StartPage.Co2NotDisappear")}
            </StyledTypography>
            <StyledTypography align="center" variant="h1" mb={1}>
              {t("CO2Calculator.StartPage.LearnCo2")}
            </StyledTypography>
          </Grid>
          <Grid item mb={8}>
            <Grid mt={1} container mb={2} alignItems="center" justifyContent="center">
              {!user ? (
                <>
                  <Grid item>
                    <Button
                      sx={{ minWidth: "250px", display: "block" }}
                      variant="contained"
                      color="success"
                      onClick={registerClick}
                    >
                      {t("CO2Calculator.StartPage.CalcCo2Here")}
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Button sx={{ minWidth: "250px" }} variant="contained" color="secondary" onClick={changeAccount}>
                      {t("Authorization.Buttons.ChangeAccount")}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            {!user && (
              <Grid container alignItems="center" mb={2} justifyContent="center">
                <Grid item>
                  <Button sx={{ minWidth: "250px" }} variant="contained" color="secondary" onClick={loginClick}>
                    {t("CO2Calculator.StartPage.ContinueToClimatePlan")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Banner>
  );
};

export default CommercialWelcomePart;
