import React from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Tender } from "../../../../definitions/model/ServiceBook";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { convertToCurency } from "../../../../services/converter.service";

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
  },
}));

type TenderCardProps = {
  tender: Tender;
  onSelect: (id: string) => void;
};

const TenderCard = ({ tender, onSelect }: TenderCardProps): JSX.Element => {
  const { t } = useTranslation();
  const handleChangeHouseClick = (reportId: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onSelect(reportId);
    handleClose(e);
  };
  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <StyledCard variant="outlined" onClick={handleChangeHouseClick(tender.Id)}>
      <CardHeader
        title={`${tender.Name}`}
        //subheader={tender.CreatedBy}
      />
      <CardContent>
        <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
          <Typography variant="h4">{new Date(tender.Created).toLocaleDateString("da-dk")}</Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="caption">{t("ServiceBook.Tender.StandardPrice")}</Typography>
          <Grid container justifyContent="space-between"></Grid>
          <Typography>{convertToCurency(tender.StandardPrice, ".")}</Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="caption">
            <Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2FootprintKilograms" />
          </Typography>
          <Grid container justifyContent="space-between"></Grid>
          <Typography>{convertToCurency(tender.CO2FootprintKilograms, ".")}</Typography>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default TenderCard;
