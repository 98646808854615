import { Reducer } from "redux";
import { AppAction } from "../../definitions/Action";
import { NavigationActionTypes } from "./navigation.actiontypes";
import { NavigationItem, PartnersItem } from "../../definitions/Menu";
import i18n from "../../i18n";

export interface NavigationStoreState {
  unitNavigationItems: NavigationItem[];
  buildingNavigationItems: NavigationItem[];
  partnersItems: PartnersItem[] | null;
  loading: boolean;
  error: string | null;
}

const menuItemData = {
  Titles: [],
  TargetUrl: "",
  IsPublished: true,
  Rating: 0,
  ShowInTopBar: true,
  RequireLogin: true,
  ShowAtStartPage: false,
  BackgroundImageUrl: "",
  UnitSensitive: false,
  UserSensitive: true,
  OrderNumber: 0,
  Id: "",
  Created: new Date(),
  Updated: new Date(),
};
export const initialState: NavigationStoreState = {
  unitNavigationItems: [],
  buildingNavigationItems: [
    {
      ...menuItemData,
      Titles: [
        { Language: "da", Text: i18n.t("BuildingManagement.BuildingsList", { lng: "da" }) },
        { Language: "en", Text: i18n.t("BuildingManagement.BuildingsList", { lng: "en" }) },
      ],
      TargetUrl: "/buildings",
    },
    {
      ...menuItemData,
      OrderNumber: 1,
      Titles: [
        { Language: "da", Text: i18n.t("Building.Building", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.Building", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route",
      UnitSensitive: true,
    },
    {
      ...menuItemData,
      OrderNumber: 2,
      Titles: [
        { Language: "da", Text: i18n.t("Building.Flats", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.Flats", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route/flats",
      UnitSensitive: true,
    },
    {
      ...menuItemData,
      OrderNumber: 3,
      Titles: [
        { Language: "da", Text: i18n.t("Building.Manager", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.Manager", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route/admin",
      UnitSensitive: true,
    },
    {
      ...menuItemData,
      OrderNumber: 4,
      Titles: [
        { Language: "da", Text: i18n.t("Building.Documents", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.Documents", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route/documents",
      UnitSensitive: true,
    },
    {
      ...menuItemData,
      OrderNumber: 5,
      Titles: [
        { Language: "da", Text: i18n.t("Building.Workers", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.Workers", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route/workers",
      UnitSensitive: true,
    },
    {
      ...menuItemData,
      OrderNumber: 6,
      Titles: [
        { Language: "da", Text: i18n.t("Building.Tender", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.Tender", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route/tenders",
      UnitSensitive: true,
    },
    {
      ...menuItemData,
      OrderNumber: 7,
      Titles: [
        { Language: "da", Text: i18n.t("Building.ServiceBook", { lng: "da" }) },
        { Language: "en", Text: i18n.t("Building.ServiceBook", { lng: "en" }) },
      ],
      TargetUrl: "/buildings/building-route/servicebook",
      UnitSensitive: true,
    },
  ],
  partnersItems: null,
  loading: false,
  error: null,
};

const navigationReducer: Reducer<NavigationStoreState, AppAction> = (
  state: NavigationStoreState = initialState,
  action: AppAction
): NavigationStoreState => {
  switch (action.type) {
    case NavigationActionTypes.NAVIGATION_OPERATION_FAILED:
    case NavigationActionTypes.PARTNERS_OPERATION_FAILED:
      return { ...state, loading: false, error: action.payload };
    case NavigationActionTypes.GET_PARTNERS_ITEMS:
    case NavigationActionTypes.GET_NAVIGATION_ITEMS:
      return { ...state, loading: true };
    case NavigationActionTypes.GET_NAVIGATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        unitNavigationItems: [
          {
            ...menuItemData,
            Titles: [
              { Language: "da", Text: i18n.t("Menu.MyOverview", { lng: "da" }) },
              { Language: "en", Text: i18n.t("Menu.MyOverview", { lng: "en" }) },
            ],
            TargetUrl: "/overview",
          },
          ...action.payload.items,
        ],
      };
    case NavigationActionTypes.GET_PARTNERS_ITEMS_SUCCEEDED:
      return {
        ...state,
        partnersItems: action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
