interface ServiceBookReportObject {
  Id: string;
  Label: string;
  Name: string;
  Description: string;
}

export interface OfferRequest {
  Id: string;
  Code: string;
  Name: string;
  Created: Date;
  Modified: Date;
  Status: number;
  OwnerName: string;
  OwnerNote: string;
  Craftsmen: string[];
  ExpectedOffersCount: number;
}

export interface ServiceBookOffer {
  Id: string;
  Code: string;
  OwnerNote: string;
  OfferRequestId: string;
  CraftsmanId: string;
  CompanyName: string;
  CraftsmanName: string;
  Phone: string;
  CraftsmanNote: string;
  Version: number;
  Status: number;
  Created: Date;
  Modified: Date;
  Objects: OfferObject[];
}

export interface OfferObject extends ServiceBookReportObject {
  Parameters: OfferParameter[];
}

export type Assignment = {
  Id: string;
  OfferId: string;
  OfferRequestId: string;
  Code: string;
  CraftsmanId: string;
  CraftsmanName: string;
  CompanyName: string;
  CraftsmanTypes: number[];
  EstimatedTime: number;
  EstimatedPrice: number;
  EstimatedMaterialPrice: number;
  CraftsmanNote: string;
  OfferNote: string;
  Created: Date;
  Modified: Date;
  Status: number;
};

export interface OfferParameter {
  Id: string;
  Active: boolean;
  OfferId: string;
  CraftsmanId: string;
  CraftsmanTypes: number[];
  EstimatedTime: number;
  EstimatedPrice: number;
  EstimatedMaterialPrice: number;
  CraftsmanNote: string;
  SkipRepair: boolean;
  ItemId: string;
  ObjectId: string;
  OfferRequestId: string;
  OfferRequestCode: string;
  OfferRequestDate: Date;
  Label: string;
  Parameter: string;
  Value: string;
  OwnerNote: string;
  Description: string;
  Pictures: string[];
}

export interface ServiceBookReportParameter {
  Id: string;
  TimeEstimate: TimeToFinish;
  PriceEstimate: number;
  MissingCraftsmen: number[];
  Assignments: Assignment[];
  ItemId: string;
  ObjectId: string;
  OfferRequestId: string;
  OfferRequestCode: string;
  OfferRequestDate: Date;
  Label: string;
  Parameter: string;
  Value: string;
  Descriptions: ParameterDescription[];
  OwnerNote: string;
  Pictures: string[];
}

export interface ParameterDescription {
  Id: string;
  Description: string;
  Value: string;
  Pictures: { Url: string }[];
}

export interface ServiceBookReportSection {
  Id: string;
  Label: string;
  Name: string;
  Parameters: ServiceBookReportParameter[];
}

export interface ServiceBookInspectionObject extends ServiceBookReportObject {
  Sections: ServiceBookReportSection[];
  Pictures: string[];
}

export type ServiceBookHouseData = {
  UnitType?: string;
  Postcode: number;
  Address: string;
  City: string;
  HouseId: string;
  ReportDate: Date;
  Latitude: number;
  Longitude: number;
  Owner: string;
  OwnerEmail: string;
  Municipality: string;
  Cadastral: string;
  CaseNumber: number;
  Picture: string;
};

export type ServiceBookReport = {
  Id: string;
  Offers: ServiceBookOffer[];
  OfferRequests: OfferRequest[];
  Data: ServiceBookHouseData;
  Created: Date;
  Modified: Date;
  Objects: ServiceBookInspectionObject[];
  WishList: ReportWishListItem[];
  Evaluation: number;
};

export type ServiceBookReportPreview = {
  OriginalReportId: string;
  OriginalModified: string;
  Name: string;
  CaseNumber: number;
  Created: string;
  Modified: string;
  ReportDate: string;
  Approved: boolean;
  CreatedBy: string;
  Id: string;
  CompletionPercentage: number;
};

export type ServiceBookOfferRequestContract = {
  reportId: string;
  itemsToFix: {
    id: string;
    ownerNote: string;
    craftsmanIds: string[];
  }[];
};

export type CraftsmanTaskList = {
  Id: string;
  Created: Date;
  Modified: string;
  CraftsmanName: string;
  CraftsmanPhone: string;
  CraftsmanEmail: string;
  CraftsmanId: string;
  Tasks: CraftsmanTask[];
  TotalPrice: number;
  Discount: number;
  Version: number;
  Status: CraftsmanTaskListStatus;
};

export type CraftsmanTask = {
  Id: string;
  Code: number;
  Header: string;
  Craftsmen: number[];
  EstimatedTime: number;
  EstimatedPrice: number;
  EstimatedMaterialPrice: number;
  CraftsmanNote: string;
  Status: CraftsmanTaskStatus;
  Pictures: CraftsmanTaskPicture[];
  StatusChanged: string;
  ActorRole: TaskActorRole;
  Descriptions: ParameterDescription[];
};

export type CraftsmanTaskPicture = {
  Id: string;
  ItemId: string;
  ObjectId: string;
  ReportId: string;
  Url: string;
};

export enum CraftsmanTaskStatus {
  None = 0,
  New = 1,
  InProgress = 2,
  Done = 3,
  Cancelled = 4,
  Shared = 5,
}

export enum CraftsmanTaskListStatus {
  None = 0,
  New = 1,
  Accepted = 2,
  Shared = 4,
  InProgress = 8,
  Finished = 16,
  Cancelled = 32,
}

export enum TaskActorRole {
  Customer = 0,
  Craftsman = 1,
}

export type ServiceTargetType = "unit" | "building";

export interface ServiceBookSearchResult<T> {
  Results: T[];
  Count: number;
}

export type ServiceBookItemToFix = {
  id: string;
  name: string;
  ownerNote: string;
  missingCraftsmen: number[];
};

export type ServiceBookItemCraftsmenSelection = {
  item: ServiceBookItemToFix;
  craftsmenIds: string[];
  ownerNote: string;
};

export enum TimeToFinish {
  Undefined = 0,
  Now = 1,
  OneToThreeYears = 2,
  ThreeToFiveYears = 3,
}

export type ReportWishListItem = {
  Id: string;
  Label: string;
  PriceEstimate: number;
};

export interface CustomerReportState {
  reportId: string;
  CustomerItems: CustomerItem[];
  Consents: ConsentState[];
}

export interface ConsentState {
  ManagerId: string;
  Name: string;
  Given: boolean;
}

export interface CustomerItem {
  [key: string]: string | boolean;
  Id: string;
  Diy: boolean;
  Finished: boolean;
}

export enum ServiceReportType {
  BasicReport = 0,
  Condition = 1,
}

export interface Tender {
  Id: string;
  Created: Date;
  Modified: Date;
  CreatedBy: string;
  StandardPrice: number;
  CO2FootprintKilograms: number;
  PropertyId: string;
  PropertyName: string;
  Picture: string;
  Link_3DModel: string;
  DecisionMakers: string[];
  SigningRules: string;
  ConstructionAdvisor: string;
  PropertyAdministrator: string;
  Name: string;
  TypeSpecificConditions: string;
  Description: string;
  Area: number;
  DemolitionReportDate: Date | null;
  BuildingPermitDate: Date | null;
  Sections: TenderSection[];
  Contractors: string[];
}

export interface TenderSection {
  Id: string;
  Name: string;
  Description: string;
  Items: TenderItem[];
}

export interface TenderItem {
  Id:string;
  Name: string;
  Description: string;
  MaterialId: string;
  PricePerItem: number;
  CO2EmissionPerItem: number;
  Quantity: number;
  WorkHours: number;
  WorkHourPrice: number;
}

export interface CreateTenderRequest {
  StandardPrice: number;
  CO2FootprintKilograms: number;
  PropertyId: string;
  PropertyName: string;
  Picture: string;
  Link_3DModel: string;
  DecisionMakers: string[];
  SigningRules: string;
  ConstructionAdvisor: string;
  PropertyAdministrator: string;
  Name: string;
  TypeSpecificConditions: string;
  Description: string;
  Area: number;
  DemolitionReportDate: Date | null;
  BuildingPermitDate: Date | null;
}

export interface UpdateTenderRequest {
  TenderId: string;
  StandardPrice: number;
  CO2FootprintKilograms: number;
  PropertyId: string;
  PropertyName: string;
  Picture: string;
  Link_3DModel: string;
  DecisionMakers: string[];
  SigningRules: string;
  ConstructionAdvisor: string;
  PropertyAdministrator: string;
  Name: string;
  TypeSpecificConditions: string;
  Description: string;
  Area: number;
  DemolitionReportDate: Date | null;
  BuildingPermitDate: Date | null;
  Sections: TenderSection[];
}
