type AnyObject = Record<string, unknown> & { [key: string]: unknown };
type Style = AnyObject[] | AnyObject | string;
type GrapesModel = AnyObject;
type GrapesBlock = AnyObject;
// Not all methods are present in types. Full list: https://grapesjs.com/docs

export type GrapesComponent = Partial<{
  type: string;
  tagName: string;
  attributes: AnyObject;
  name: string;
  removable: boolean | string;
  draggable: boolean | string;
  droppable: boolean | string;
  badgable: boolean | string;
  stylable: boolean | string[];
  unstylable: string[];
  highlightable: boolean;
  copyable: boolean;
  resizable: boolean;
  editable: boolean;
  layerable: boolean;
  selectable: boolean;
  hoverable: boolean;
  void: boolean;
  content: string;
  icon: string;
  script: string | any;
  traits: Array<AnyObject | string>;
  propagate: string[];
  toolbar: AnyObject[];
  components: GrapesComponent[];
  style: AnyObject;
  classes: string[];

  [key: string]: unknown;
}>;

export type GrapesBlockManager = {
  getConfig: () => AnyObject;
  add: (id: string, options: GrapesBlockOptions) => GrapesBlock;
  get: (id: string) => GrapesBlock;
  getAll: () => GrapesBlock[];
  getAllVisible: () => GrapesBlock[];
};

export type GrapesEditor = {
  getConfig: (prop: string) => AnyObject;
  getHtml: () => string;
  getCss: (options?: AnyObject) => string;
  getJs: () => string;
  getComponents: () => AnyObject[];
  getWrapper: () => AnyObject[];
  getContainer: () => HTMLElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getModel: () => { get: (name: string) => any };
  setComponents: (
    components: GrapesComponent[] | GrapesComponent,
    options?: AnyObject
  ) => GrapesEditor;
  addComponents: (
    components: GrapesComponent[] | GrapesComponent,
    options?: AnyObject
  ) => GrapesComponent[];
  getStyle: () => AnyObject;
  setStyle: (style: Style, options?: AnyObject) => AnyObject;
  getSelected: () => GrapesModel;
  setDevice: (name: string) => GrapesEditor;
  getDevice: () => string;
  runCommand: (id: string, options?: AnyObject) => unknown;
  stopCommand: (id: string, options?: AnyObject) => unknown;
  store: <T = AnyObject>(callback: (data: T) => void) => T;
  load: <T = AnyObject>(callback: (data: T) => void) => T;
  on: (eventName: string, callback: (e: AnyObject) => void) => GrapesEditor;
  once: (eventName: string, callback: (e: AnyObject) => void) => GrapesEditor;
  off: (eventName: string, callback: (e: AnyObject) => void) => GrapesEditor;
  trigger: (eventName: string) => GrapesEditor;
  destroy: () => void;
  render: () => HTMLElement;
  refresh: () => HTMLElement;
  BlockManager: GrapesBlockManager;
  DomComponents: any;
  CssComposer: any;
  Commands: any;
};

type PluginsManager = {
  add: (
    id: string,
    fn: (editor: GrapesEditor, options: AnyObject) => void
  ) => void;
  get: (id: string) => AnyObject;
  getAll: () => void;
};

export type GrapesJs = {
  editors: GrapesEditor[];
  init: (options: AnyObject) => void;
  plugins: PluginsManager;
};

export type GrapesBlockOptions = {
  label: string;
  content: string | AnyObject;
  category: string | AnyObject;
  attributes: AnyObject;
  textable?: number;
};

export type ParserConfig = {
  textTags: string[];
  keepEmptyTextNodes: boolean;
};

export enum GrapesTypes {
  ReactComponentsPlugin = "react-components-plugin",
  RegisterFormComponent = "gjs-register-form",
  LeadFormComponent = "gjs-lead-form",
  BackButtonComponent = "gjs-back-button",
  UsersCounterComponent = "gjs-users-counter",
  UserPropertyComponent = "gjs-user-property",
  SmsLinkComponent = "gjs-sms-link",
  UnsubscribeLinkComponent = "gjs-unsubscribe-link",
  UpdateMaterialStylesCommand = "update-mat-ui-styles-command",
  LoadHtmlTemplate = "load-html-template",
  SearchFormComponent = "gjs-search-form",
  UnitAddressComponent = "gjs-unit-address",
  EntityFieldComponent = "gjs-entity-field",
  MyhouseLinkComponent = "gjs-myhouse-link",
  EmailEntityFieldComponent = "gjs-email-entity-field",
  HouseDataButtonComponent = "gjs-house-data-button",
  CO2CompaniesComponent = "gjs-co2-companies",
  DomainStringComponent = "gsj-domain-string",
  VideoComponent = "gsj-video",
}

export type LoadComponentOptions = {
  categoryLabel: string;
};
