import React, { useState, useEffect, SyntheticEvent } from "react";
import {
  Grid,
  TextField,
  Typography,
  Link,
  FormLabel,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Collapse,
  Checkbox,
} from "@mui/material";
import { PhoneField } from "./FormattedFields";
import { useForm, FormProvider } from "react-hook-form";

import BaseDialog from "../../components/Dialogs/BaseDialog";
import { RegisterLeadContract } from "../../definitions/LiteCapaign";
import { Trans, useTranslation } from "react-i18next";
import MyhouseCheckbox from "./MyhouseCheckbox";
import DataPoliticDialog from "../Dialogs/DataPoliticDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ControllerConnector, TextFieldConnector } from "./FormConnector";
import { PhoneRegexp } from "../../services/validation.service";
import CircularProgressButton from "./CircularProgressButton";
import { styled } from "@mui/material/styles";
import { matchIsValidTel } from "mui-tel-input";

type ContactMeBoxProps = {
  initialValues: {
    name: string;
    phone: string;
    email?: string;
  };
  utmSource: string;
  loading: boolean;
  userId?: string;
  addLead: (data: RegisterLeadContract) => Promise<void>;
  closeDialog?: () => void;
  showTerms?: boolean;
  keepFormData?: boolean;
  contactButtonText?: string;
  confirmationMessage?: string;
  datePickerType?: DatePickerType;
  EstimatedSalePrice?: number;
  VurderingsPortalenPrice?: number;
  OtherPrices?: string;
  hideEmail?: boolean;
  hideName?: boolean;
  hidePhone?: boolean;
};

export type DatePickerType = "date" | "datetime-local";

type FormData = {
  Name: string;
  Phone: string;
  DataPolicy: boolean;
  Conscent: boolean;
  ToBeContaxtedOn: Date | null;
  Email?: string;
};

const StyledCircularProgressButton = styled(CircularProgressButton)(() => ({
  minWidth: "200px",
  "& span": {
    color: "#fff",
    width: "17px",
    height: "17px",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  top: "-10px",
  position: "relative",
}));

const ContactMeBox = (props: ContactMeBoxProps): JSX.Element => {
  const [openConfirmation, setOpenConfimation] = useState(false);
  const { t } = useTranslation("translation");
  const [openPolitic, setOpenPolitic] = useState(false);
  const [longConsent, setLongConsent] = useState(false);
  const [longPolicy, setLongPolicy] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { initialValues, utmSource, loading, addLead, keepFormData, hideEmail = true } = props;
  const today = new Date().toISOString().slice(0, 16);

  // //reset values because they're cached on the first render and
  // //it prevents validation until the input onChange triggers
  // useEffect(() => {
  //   reset({
  //     Name: initialValues.name,
  //     Phone: initialValues.phone,
  //     Email: initialValues.email,
  //   });
  // }, [initialValues.name, initialValues.phone, initialValues.email]);

  const methods = useForm<FormData>({
    defaultValues: {
      Name: initialValues.name,
      Phone: initialValues.phone,
      ToBeContaxtedOn: null,
      DataPolicy: false,
      Conscent: false,
      Email: initialValues.email ?? "",
    },
  });

  const submit = async (data: FormData, e: any) => {
    try {
      setLocalLoading(true);
      const createLead: RegisterLeadContract = {
        UserId: null,
        UtmSource: utmSource,
        Name: data.Name,
        Email: data.Email || "",
        Phone: data.Phone,
        ToBeContactedOn: data.ToBeContaxtedOn,
        MyhousePrice: props.EstimatedSalePrice ? String(props.EstimatedSalePrice) : "",
        VurderingsPortalenPrice: props.VurderingsPortalenPrice ? String(props.VurderingsPortalenPrice) : "",
        OtherPrices: props.OtherPrices ? props.OtherPrices : "",
      };
      await addLead(createLead);
      setOpenConfimation(true);
      reset({
        Name: keepFormData ? initialValues.name : "",
        Phone: keepFormData ? initialValues.phone : "",
        Email: keepFormData ? initialValues.email : "",
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLocalLoading(false);
    }
  };

  const {
    handleSubmit,
    register,
    // unregister,
    reset,
    formState: { errors },
  } = methods;

  // useEffect(() => {
  //   if (!props.hidePhone)
  //     register("Phone", { required: true, pattern: PhoneRegexp, validate: (e: any) => matchIsValidTel(e) });
  //   if (!props.hideName) register("Name", { required: true });
  //   if (!hideEmail) register("Email", { required: true });
  //   return () => {
  //     unregister("Phone");
  //     unregister("Name");
  //     unregister("Email");
  //   };
  // }, []);

  useEffect(() => {
    console.log(errors);
  },[errors]);

  const onPoliticClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenPolitic(true);
  };
  const seeAllConsent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLongConsent((prev) => !prev);
  };
  const seeAllPolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLongPolicy((prev) => !prev);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container justifyContent={"center"} spacing={2}>
          {!props.hideName && (
            <Grid item xs={12} sm={4}>
              <TextFieldConnector register={register("Name", { required: true })}>
                <TextField
                  margin={"dense"}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={t("Base.ContactMeBox.Name")}
                  placeholder={t("Base.ContactMeBox.EnterName") ?? ""}
                  error={!!errors.Name}
                />
              </TextFieldConnector>
            </Grid>
          )}

          {!props.hidePhone && (
            <Grid item xs={12} sm={4}>
              <ControllerConnector
                name="Phone"
                defaultValue={initialValues.phone || ""}
                rules={{
                  required: true,
                  pattern: PhoneRegexp,
                  validate: (e: any) => matchIsValidTel(e),
                }}
              >
                <PhoneField
                  margin={"dense"}
                  fullWidth
                  variant="outlined"
                  label={t("Base.ContactMeBox.PhoneNumber")}
                  size="small"
                  placeholder={t("Base.ContactMeBox.EnterPhoneNumber") ?? ""}
                  error={!!errors.Phone}
                />
              </ControllerConnector>
            </Grid>
          )}

          {!hideEmail && (
            <Grid item xs={12} sm={4}>
              <TextFieldConnector register={register("Email", { required: true })}>
                <TextField
                  margin={"dense"}
                  type="email"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={t("Base.ContactMeBox.Email")}
                  placeholder={t("Base.ContactMeBox.Email") ?? ""}
                  error={!!errors.Email}
                />
              </TextFieldConnector>
            </Grid>
          )}

          {props.datePickerType && (
            <Grid item xs={12} sm={4}>
              <TextFieldConnector register={register("ToBeContaxtedOn", { required: props.datePickerType })}>
                <TextField
                  margin={"dense"}
                  fullWidth
                  size="small"
                  variant="outlined"
                  label={t("Pages.PropertyFacts.DateTime")}
                  type={props.datePickerType}
                  error={!!errors.ToBeContaxtedOn}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ disableAnimation: true, shrink: true }}
                  inputProps={{ min: today }}
                />
              </TextFieldConnector>
            </Grid>
          )}
          <Grid item container justifyContent={"center"}>
            <StyledCircularProgressButton
              type="submit"
              inProgress={loading || localLoading}
              color="secondary"
              //disabled={!!errors.Name || !!errors.Phone}
            >
              {t(props.contactButtonText || "Base.ContactMeBox.ContactMe")}
            </StyledCircularProgressButton>
          </Grid>
          {props.showTerms && (
            <Grid item container direction="column">
              <FormLabel style={{ display: "inline-flex", alignItems: "center" }}>
                <ControllerConnector name="Conscent" rules={{ required: true }} type="checkbox">
                  <MyhouseCheckbox error={!!errors.Conscent} />
                </ControllerConnector>
                <Grid container>
                  <Typography variant="subtitle1">{t("CO2Calculator.GreenGuides.Subtitle2")}</Typography>
                  {!isXs ? (
                    <>
                      <Typography variant="body1">
                        <Trans
                          components={{ sub: <sub /> }}
                          values={{ hostname: window.location.hostname }}
                          i18nKey="CO2Calculator.GreenGuides.Paragraph3"
                        />
                      </Typography>
                      <Typography variant="body1">{t("CO2Calculator.GreenGuides.Paragraph4")}</Typography>
                    </>
                  ) : (
                    <>
                      <Grid container>
                        <Collapse in={longConsent} collapsedSize={20}>
                          <Grid container>
                            <Grid item xs={11}>
                              <Typography variant="body2">
                                {t("CO2Calculator.GreenGuides.Paragraph3", { hostname: window.location.hostname })}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <StyledFormControlLabel
                                control={
                                  <Checkbox
                                    checked={longConsent}
                                    onChange={seeAllConsent}
                                    icon={<ExpandMoreIcon />}
                                    checkedIcon={<ExpandLessIcon />}
                                  />
                                }
                                label=""
                              />
                            </Grid>
                          </Grid>

                          <Typography variant="body2">{t("CO2Calculator.GreenGuides.Paragraph4")}</Typography>
                        </Collapse>
                      </Grid>
                    </>
                  )}
                </Grid>
              </FormLabel>

              <FormLabel style={{ display: "inline-flex", alignItems: "center" }}>
                <ControllerConnector name="Policy" rules={{ required: true }} type="checkbox">
                  <MyhouseCheckbox error={!!errors.DataPolicy} />
                </ControllerConnector>
                <Grid container>
                  <Typography variant="subtitle1">{t("CO2Calculator.GreenGuides.Subtitle3")}</Typography>
                  {!isXs ? (
                    <>
                      <Typography variant="body1">
                        {t("CO2Calculator.GreenGuides.Paragraph5", { hostname: window.location.hostname })}
                      </Typography>
                      <Typography variant="body1">
                        <Link color="inherit" underline="always" onClick={onPoliticClick}>
                          {t("CO2Calculator.GreenGuides.Paragraph6", { hostname: window.location.hostname })}
                        </Link>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Grid container>
                        <Collapse in={longPolicy} collapsedSize={20}>
                          <Grid container>
                            <Grid item xs={11}>
                              <Typography variant="body2">
                                {t("CO2Calculator.GreenGuides.Paragraph5", { hostname: window.location.hostname })}
                              </Typography>
                              <Typography variant="body2">
                                <Link color="inherit" underline="always" onClick={onPoliticClick}>
                                  {t("CO2Calculator.GreenGuides.Paragraph6", { hostname: window.location.hostname })}
                                </Link>
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <StyledFormControlLabel
                                control={
                                  <Checkbox
                                    checked={longPolicy}
                                    onChange={seeAllPolicy}
                                    icon={<ExpandMoreIcon />}
                                    checkedIcon={<ExpandLessIcon />}
                                  />
                                }
                                label=""
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                    </>
                  )}
                </Grid>
              </FormLabel>

              <DataPoliticDialog open={openPolitic} closeDialog={() => setOpenPolitic(false)} />
            </Grid>
          )}
        </Grid>
        <BaseDialog
          closeDialog={() => {
            setOpenConfimation(false);
            //close main dialog
            if (props.closeDialog) props.closeDialog();
          }}
          open={openConfirmation}
        >
          <Typography variant="body1" align="center">
            {t(props.confirmationMessage || "Base.ContactMeBox.MessageSentToBeContacted")}
          </Typography>
        </BaseDialog>
      </form>
    </FormProvider>
  );
};

export default ContactMeBox;
