import React, { ChangeEvent, useState, useEffect } from "react";
import {
  ListItem,
  Grid,
  Typography,
  Divider,
  IconButton,
  Collapse,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  styled,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight, HighlightOff, StarHalf, Verified } from "@mui/icons-material";
import { Rating } from "@mui/material";

import { CraftsmanType, WorkerModel } from "../../../definitions/model/Worker";
import RatingDialog from "../../../components/Dialogs/RatingDialog";
import { useAlertContext } from "../../../components/Base/MyhouseAlert";
import { useTranslation } from "react-i18next";

const GridContent = styled(Grid)(({ theme }) => ({
  "& p": {
    width: "80%",
    color: theme.palette.primary.dark,
    margin: "10px 0",
  },
}));

const WorkerBodyGrid = styled(Grid)(({ theme }) => ({
  marginTop: 5,
  padding: 5,
  boxSizing: "border-box",
  position: "relative",
  background: theme.palette.background.grayPaper,
  borderRadius: 10,
}));

const CloseWorkerIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 0,
  top: 0,
}));

type WorkerComponentProps = {
  worker: WorkerModel;
  linked: boolean;
  showControls: boolean;
  error: string | null;
  linkWorkerToUnit: (workerId: string) => Promise<void | undefined>;
  unlinkWorkerFromUnit: (workerId: string) => Promise<void>;
  rateWorker?: (workerId: string, rating: number, comment: string | null) => Promise<void>;
  linking?: boolean;
  linkLabel?: string;
};

const WorkerComponent = (props: WorkerComponentProps) => {
  const { t } = useTranslation("translation");

  const { worker, linked, showControls } = props;
  const [open, setOpen] = useState(false);
  const [showRatingDialog, setShowRatingDialog] = useState(false);
  const [linking, setLinking] = useState(false);
  const { showAlert } = useAlertContext();

  useEffect(() => {
    if (props.error) showAlert({ severity: "error", text: props.error });
  }, [props.error]);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const linkHandler = async (e: ChangeEvent<unknown>, checked: boolean) => {
    setLinking(true);

    try {
      if (checked) {
        await props.linkWorkerToUnit(worker.Id);
      } else {
        await props.unlinkWorkerFromUnit(worker.Id);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLinking(false);
    }
  };

  const rateHandler = (rating: number, comment: string | null) => {
    if (!props.rateWorker) return;
    props.rateWorker(worker.Id, rating, comment);
    setShowRatingDialog(false);
  };

  return (
    <>
      <ListItem>
        <Grid container direction="column" sx={{ minHeight: "63px" }}>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item sm={6} xs={12}>
              <Grid item container direction="column" alignItems="flex-start">
                <Grid item container direction="row" alignItems="center">
                  <Typography variant="body1" fontWeight={600} sx={{ marginRight: "10px" }}>
                    {worker.Organization}
                  </Typography>
                  {worker.GreenTag && <Verified color="secondary" fontSize="small" />}
                  {!!worker.Rating && (
                    <Rating value={worker.RatingFromCurrentUser || worker.Rating} readOnly={true} size="small" />
                  )}
                </Grid>
                <Typography variant="body2" fontStyle={"italic"}>
                  {worker.Competencies.map((competency) =>
                    t(CraftsmanType.find((t) => t.key === competency)?.value || "")
                  )
                    .sort()
                    .join(", ")}
                </Typography>
                <Typography variant="body2">{worker.Address}</Typography>
                <Typography variant="body2">
                  {!!worker.PostCode && worker.PostCode} {worker.City}
                </Typography>
              </Grid>
            </Grid>
            {showControls ? (
              <Grid item sm={6} xs={12} container alignItems="center" justifyContent="flex-end">
                {(props.linking || linking) && <CircularProgress />}
                {!(props.linking || linking) && (
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label={props.linkLabel ?? t("ServiceBook.Workers.LinkToMyHouse")}
                    labelPlacement="start"
                    checked={linked}
                    onChange={linkHandler}
                  />
                )}
                {!(props.linking || linking) && linked && props.rateWorker && (
                  <IconButton title={t("Base.Dialog.Assess") ?? ""} onClick={() => setShowRatingDialog(true)}>
                    <StarHalf />
                  </IconButton>
                )}
                <IconButton onClick={toggleOpen}>
                  {open && <KeyboardArrowDown />}
                  {!open && <KeyboardArrowRight />}
                </IconButton>
              </Grid>
            ) : (
              <>
                {linked && (
                  <GridContent>
                    <Typography variant="body2">{t("ServiceBook.Workers.Associated")}</Typography>
                  </GridContent>
                )}
              </>
            )}
          </Grid>
          <Collapse in={open}>
            <WorkerBodyGrid container direction="row" justifyContent="space-between" spacing={1}>
              <CloseWorkerIconButton onClick={toggleOpen} size="large">
                <HighlightOff />
              </CloseWorkerIconButton>
              <Grid item>
                <Grid item direction="column" container>
                  <Grid item>
                    {!worker.Organization && (
                      <Typography variant="body1">
                        {worker.FirstName} {worker.LastName}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      {t("Base.Popovers.Telephone")}: {worker.Phone}
                    </Typography>
                    {Boolean(worker.Mobile) && (
                      <Typography variant="body2">
                        {t("ServiceBook.Workers.Mobile")}
                        {": "}
                        {worker.Mobile}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      {t("ServiceBook.Workers.Email")}
                      {worker.Email}
                    </Typography>
                    {Boolean(worker.CVR) && <Typography variant="body2">CVR: {worker.CVR}</Typography>}

                    {Boolean(worker.AuthNumber) && (
                      <Typography variant="body2">
                        {" "}
                        {t("ServiceBook.Workers.AuthNumber")}: {worker.AuthNumber}
                      </Typography>
                    )}

                    {Boolean(worker.City) && (
                      <Typography variant="body2">
                        {t("ServiceBook.Workers.City")}: {worker.City}
                      </Typography>
                    )}

                    {Boolean(worker.Address) && (
                      <Typography variant="body2">
                        {t("Base.Popovers.Address")}: {worker.Address}
                      </Typography>
                    )}

                    {Boolean(worker.PostCode) && (
                      <Typography variant="body2">
                        {t("Base.Popovers.ZipCode")}: {worker.PostCode}
                      </Typography>
                    )}

                    {Boolean(worker.FirstName || worker.LastName) && (
                      <Typography variant="body2">
                        {t("ServiceBook.Workers.ContactPerson")}: {worker.FirstName} {worker.LastName}
                      </Typography>
                    )}

                    {Boolean(worker.WebSite) && (
                      <Typography variant="body2">
                        {t("Base.Popovers.Website")}: {worker.WebSite}
                      </Typography>
                    )}

                    {Boolean(worker.Comment) && (
                      <Typography variant="body2">
                        {t("Base.Dialog.Comment")}: {worker.Comment}
                      </Typography>
                    )}

                    {worker.CommentFromCurrentUser && (
                      <Typography variant="body2">
                        {t("ServiceBook.Workers.MyComment")}
                        {worker.CommentFromCurrentUser}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </WorkerBodyGrid>
          </Collapse>
        </Grid>
      </ListItem>
      <Divider />
      <RatingDialog
        open={showRatingDialog}
        existingRating={worker.RatingFromCurrentUser}
        existingComment={worker.CommentFromCurrentUser}
        showComment={true}
        objectName={worker.Organization || `${worker.FirstName} ${worker.LastName}`}
        handleClose={() => setShowRatingDialog(false)}
        handleOk={rateHandler}
      />
    </>
  );
};

export default WorkerComponent;
