import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { ArrowBack } from "@mui/icons-material";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  useFieldArray,
  useForm,
  FormProvider,
  Controller,
} from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
  FormLabel,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { NIL, v4 as uuidv4 } from "uuid";
import {
  CO2EmissionSourceGroupDescription,
  CO2PlanMeta,
  CO2PlanMetaCategoryType,
  CO2PlanMetaStatsDto,
  MetaAnswer,
  MetaQuestions,
} from "../../../ClimatePlan/shared/CO2Plan";
import { ControllerConnector, TextFieldConnector } from "../../../../components/Base/FormConnector";
import { CommuneLocation } from "../../../../definitions/model/unit/Commune";
import { TransferList } from "../../../../components/Base";
import { CompanyModel } from "../../../../definitions/Company";
import { AddLinkOutlined, ExpandMore, Add, LinkOff } from "@mui/icons-material";
import { TabPanel } from "../../components/TabPanel";
import { useAdminCO2 } from "../../../../actions/admin/admin.co2plan.actions";
import MetaQuestionAnswersChart from "./components/MetaQuestionAnswersChart";

export type CO2PlanMetaItemFormData = {
  Id: string;
  DisplayName: string;
  InitialSurvey: boolean;
  TargetSurvey: boolean;
  MainToolBar: boolean;
  Companies: string[];
  Communes: number[];
  Questions: MetaQuestions[];
  TargetCategory: CO2PlanMetaCategoryType;
  PlaceAfterSourceId: string;
};

type EconomyItemEditDialogProps = {
  communes: Array<CommuneLocation>;
  companies: Array<CompanyModel>;
  co2PlanMetaItem: CO2PlanMeta | null;
  groups: CO2EmissionSourceGroupDescription[];
  backToTable: () => void;
  createCO2PlanMetaItem: (
    displayName: string,
    initialSurvey: boolean,
    targetSurvey: boolean,
    mainToolBar: boolean,
    companies: Array<string>,
    communes: Array<number>,
    questions: Array<MetaQuestions>,
    targetCategory: CO2PlanMetaCategoryType,
    placeAfterSourceId: string
  ) => Promise<void>;
  editCO2PlanMetaItem: (
    id: string,
    displayName: string,
    initialSurvey: boolean,
    targetSurvey: boolean,
    mainToolBar: boolean,
    companies: Array<string>,
    communes: Array<number>,
    questions: Array<MetaQuestions>,
    targetCategory: CO2PlanMetaCategoryType,
    placeAfterSourceId: string
  ) => Promise<void>;
};
const CO2PlansMetaItemCreation = (props: EconomyItemEditDialogProps): JSX.Element => {
  const [, co2Actions] = useAdminCO2();
  const methods = useForm<CO2PlanMetaItemFormData>();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const [selectedCompanies, setSelectedCompanies] = useState<Array<CompanyModel>>([]);
  const [selectedCommunes, setSelectedCommunes] = useState<Array<CommuneLocation>>([]);
  const [metaStats, setMetaStats] = useState<CO2PlanMetaStatsDto>();

  const [currentTab, setCurrentTab] = useState(0);

  const { t } = useTranslation("translation");

  useEffect(() => {
    if (!props.co2PlanMetaItem) {
      reset({
        Id: "",
        DisplayName: "",
        InitialSurvey: false,
        TargetSurvey: false,
        MainToolBar: false,
        Companies: [],
        Communes: [],
        Questions: [],
        TargetCategory: CO2PlanMetaCategoryType.Household,
        PlaceAfterSourceId: "",
      });
      setSelectedCompanies([]);
      setSelectedCommunes([]);
    }
  }, [props.co2PlanMetaItem]);

  useEffect(() => {
    if (props.co2PlanMetaItem != null && props.companies && props.communes) {
      setValue("DisplayName", props.co2PlanMetaItem.DisplayName);
      setValue("InitialSurvey", props.co2PlanMetaItem.InitialSurvey);
      setValue("TargetSurvey", props.co2PlanMetaItem.TargetSurvey);
      setValue("MainToolBar", props.co2PlanMetaItem.MainToolBar);
      setValue("TargetCategory", props.co2PlanMetaItem.TargetCategory);
      setValue("PlaceAfterSourceId", props.co2PlanMetaItem.PlaceAfterSourceId);
      setSelectedCompanies(
        props.co2PlanMetaItem.Companies.map((sc) => {
          return props.companies.filter((c) => c.Id === sc)[0];
        })
      );
      setSelectedCommunes(
        props.co2PlanMetaItem.Communes.map((sc) => {
          return props.communes.filter((c) => c.Commune.CommuneNumber === sc)[0];
        })
      );

      co2Actions.getCO2PlanMetaStats(props.co2PlanMetaItem.Id).then((res) => setMetaStats(res));
    }

    if (props.co2PlanMetaItem && props.co2PlanMetaItem.Questions.length) {
      setValue("Questions", props.co2PlanMetaItem.Questions);
    }
  }, [props.communes, props.companies, props.co2PlanMetaItem]);

  const submit = (values: CO2PlanMetaItemFormData) => {
    if (props.co2PlanMetaItem) {
      props
        .editCO2PlanMetaItem(
          props.co2PlanMetaItem.Id,
          values.DisplayName,
          values.InitialSurvey,
          values.TargetSurvey,
          values.MainToolBar,
          values.Companies,
          values.Communes,
          values.Questions,
          values.TargetCategory,
          values.PlaceAfterSourceId
        )
        .then(() => props.backToTable());
    } else {
      props
        .createCO2PlanMetaItem(
          values.DisplayName,
          values.InitialSurvey,
          values.TargetSurvey,
          values.MainToolBar,
          values.Companies,
          values.Communes,
          values.Questions,
          values.TargetCategory,
          values.PlaceAfterSourceId
        )
        .then(() => props.backToTable());
    }
  };

  const handleChange = (event: React.ChangeEvent<any>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item direction="row" sx={{ width: "100%" }}>
        <Box pb={2}>
          <Button onClick={props.backToTable} type="submit" variant="outlined" color="secondary">
            <ArrowBack fontSize="small" />
            {t("General.Buttons.Back")}
          </Button>
        </Box>
      </Grid>
      <Grid item md={6} sm={9} xs={12}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{
            width: "100%",
            "& .MuiButtonBase-root.MuiTab-root": {
              textTransform: "none",
            },
          }}
        >
          <Tab label="Meta item" />
          <Tab label="Answers stats" />
        </Tabs>
      </Grid>
      <Grid item container>
        <TabPanel value={currentTab} index={0}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
              <Box>
                <TextFieldConnector register={register("DisplayName", { required: true })}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    label="Name"
                    fullWidth
                    error={!!errors.DisplayName}
                  />
                </TextFieldConnector>
                <Box sx={{ marginTop: 1 }}>
                  <TransferList
                    options={props.communes}
                    selected={selectedCommunes}
                    fieldLabel="Communes"
                    placeholder="Communes"
                    onChanges={(selected) =>
                      setValue(
                        "Communes",
                        selected.map((s) => s.Commune.CommuneNumber)
                      )
                    }
                    getOptionLabel={(o: CommuneLocation) => o.Commune.CommuneName}
                    getOptionSelected={(s: CommuneLocation, o: CommuneLocation) =>
                      s.Commune.CommuneNumber === o.Commune.CommuneNumber
                    }
                  />
                </Box>
                <Box sx={{ marginTop: 1.5 }}>
                  <TransferList
                    options={props.companies}
                    selected={selectedCompanies}
                    fieldLabel="Companies"
                    placeholder="Companies"
                    onChanges={(selected: Array<CompanyModel>) =>
                      setValue(
                        "Companies",
                        selected.map((c) => c.Id)
                      )
                    }
                    getOptionLabel={(o: CompanyModel) => o.Name}
                    getOptionSelected={(s: CompanyModel, o: CompanyModel) => s.Id === o.Id}
                  />
                </Box>
                <Box>
                  <FormLabel>
                    <ControllerConnector name="InitialSurvey" type="checkbox">
                      <Checkbox />
                    </ControllerConnector>
                    <Typography variant="caption">Initial Survey</Typography>
                  </FormLabel>
                  <FormLabel>
                    <ControllerConnector name="TargetSurvey" type="checkbox">
                      <Checkbox />
                    </ControllerConnector>
                    <Typography variant="caption">Target Survey</Typography>
                  </FormLabel>
                  <FormLabel>
                    <ControllerConnector name="MainToolBar" type="checkbox">
                      <Checkbox />
                    </ControllerConnector>
                    <Typography variant="caption">Main Toolbar</Typography>
                  </FormLabel>
                </Box>
                <Box>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Insert After</InputLabel>
                    <Controller
                      name="PlaceAfterSourceId"
                      render={({ field }) => (
                        <Select
                          {...field}
                          error={!!errors.PlaceAfterSourceId}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setValue("PlaceAfterSourceId", e.target.value);
                          }}
                        >
                          <MenuItem key={0} value={NIL} style={{ padding: "10px 20px" }}>
                            As a first item
                          </MenuItem>
                          {props.groups.map((group) => (
                            <MenuItem key={group.Id} value={group.Id} style={{ padding: "10px 20px" }}>
                              {t(group.ShortName)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      control={control}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Category Type</FormLabel>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name="TargetCategory"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value={CO2PlanMetaCategoryType.Personal}
                            control={<Radio />}
                            label="Personal"
                          />
                          <FormControlLabel
                            value={CO2PlanMetaCategoryType.Household}
                            control={<Radio />}
                            label="Household"
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Box>
                {props.co2PlanMetaItem && (
                  <Box sx={{ marginTop: 1.5 }}>
                    <QuestionsList
                      control={control}
                      register={register}
                      errors={errors}
                      Questions={props.co2PlanMetaItem.Questions}
                    />
                  </Box>
                )}
              </Box>
              <Grid container spacing={1} mt={1}>
                <Grid item>
                  <Button variant="contained" type="button" onClick={props.backToTable} color="secondary">
                    {t("General.Buttons.Close")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" type="submit" color="secondary">
                    {t("General.Buttons.Save")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {metaStats &&
            metaStats.QuestionAnswers.map((qa) => (
              <MetaQuestionAnswersChart key={qa.Id} question={qa} width="100%" height={300} />
            ))}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

type QuestionsListProps = {
  control: Control<CO2PlanMetaItemFormData, any>;
  register: UseFormRegister<CO2PlanMetaItemFormData>;
  errors: FieldErrors<CO2PlanMetaItemFormData>;
  Questions: MetaQuestions[];
};

const QuestionsList = ({ control, register, errors, Questions }: QuestionsListProps) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "Questions",
  });

  const addQuestion = () => {
    append({
      Id: uuidv4(),
      Order: Questions.length + 1,
      Answers: [],
      Text: [
        { Language: "en", Text: "" },
        { Language: "da", Text: "" },
      ],
      IsMultiselect: false,
    });
  };

  return (
    <Grid container>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>Questions</AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" justifyContent="center">
            {fields.map((question, questionIndex) => (
              <>
                <StyledQuestionContainer container key={question.Id} direction="column" alignItems="center">
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="h3">Question</Typography>
                    <FormLabel sx={{}}>
                      <Checkbox
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const data = { ...question, IsMultiselect: e.target.checked };
                          update(questionIndex, data);
                        }}
                        checked={question.IsMultiselect}
                      />
                      <Typography variant="caption">Is Multiselect</Typography>
                    </FormLabel>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "6px",
                    }}
                  >
                    {question.Text.map((text, textIndex) => (
                      <TextFieldConnector
                        key={text.Language + textIndex}
                        register={register(`Questions.${questionIndex}.Text.${textIndex}.Text`)}
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          label={`Question ${text.Language}`}
                          required
                          fullWidth
                          defaultValue={text.Text}
                          error={!!errors.Questions?.[questionIndex]?.Text?.[textIndex]?.Text}
                          InputProps={{
                            endAdornment: (
                              <>
                                <Divider orientation="vertical" />
                                <IconButton onClick={() => remove(questionIndex)}>
                                  <LinkOff />
                                </IconButton>
                              </>
                            ),
                          }}
                        />
                      </TextFieldConnector>
                    ))}
                  </Grid>
                  <AnswersList
                    register={register}
                    control={control}
                    errors={errors}
                    Answers={question.Answers}
                    index={questionIndex}
                  />
                </StyledQuestionContainer>
              </>
            ))}
            <Button variant="text" startIcon={<AddLinkOutlined />} onClick={addQuestion}>
              Add Question
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

type AnswersListProps = {
  control: Control<CO2PlanMetaItemFormData, any>;
  register: UseFormRegister<CO2PlanMetaItemFormData>;
  errors: FieldErrors<CO2PlanMetaItemFormData>;
  Answers: MetaAnswer[];
  index: number;
};

const AnswersList = ({ control, register, errors, Answers, index }: AnswersListProps) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `Questions.${index}.Answers`,
  });

  const addAnswer = () => {
    append({
      Id: uuidv4(),
      Order: Answers.length + 1,
      Text: [
        { Language: "en", Text: "" },
        { Language: "da", Text: "" },
      ],
      UserValue: false,
      UserValueLabel: [
        { Language: "en", Text: "" },
        { Language: "da", Text: "" },
      ],
    });
  };

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Typography variant="h3" align="center">
        Answers
      </Typography>
      {fields.map((answer, answerIndex) => (
        <Grid
          key={answer.Id}
          container
          direction="column"
          alignItems="end"
          style={{
            marginBottom: fields.length === answerIndex + 1 ? "0" : "12px",
            width: "95%",
          }}
        >
          <Box>
            <FormLabel>
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const data = { ...answer, UserValue: e.target.checked };
                  if (data.UserValueLabel.length === 0)
                    data.UserValueLabel = [
                      { Language: "en", Text: "" },
                      { Language: "da", Text: "" },
                    ];

                  update(answerIndex, data);
                }}
                checked={answer.UserValue}
              />
              <Typography variant="caption">User Input</Typography>
            </FormLabel>
          </Box>
          {answer.UserValue &&
            answer.UserValueLabel.map((label, labelIndex) => (
              <TextFieldConnector
                key={label.Language + labelIndex}
                register={register(`Questions.${index}.Answers.${answerIndex}.UserValueLabel.${labelIndex}.Text`)}
              >
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={`Label ${label.Language}`}
                  required
                  fullWidth
                  defaultValue={label.Text}
                  style={{ width: "95%" }}
                  error={!!errors.Questions?.[index]?.Answers?.[answerIndex]?.UserValueLabel?.[labelIndex]?.Text}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Divider orientation="vertical" />
                        <IconButton onClick={() => remove(answerIndex)}>
                          <LinkOff />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              </TextFieldConnector>
            ))}
          {answer.UserValue === false &&
            answer.Text.map((text, textIndex) => (
              <TextFieldConnector
                key={text.Language + textIndex}
                register={register(`Questions.${index}.Answers.${answerIndex}.Text.${textIndex}.Text`)}
              >
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={`Answer ${text.Language}`}
                  required
                  fullWidth
                  defaultValue={text.Text}
                  style={{ width: "95%" }}
                  error={!!errors.Questions?.[index]?.Answers?.[answerIndex]?.Text?.[textIndex]?.Text}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Divider orientation="vertical" />
                        <IconButton onClick={() => remove(answerIndex)}>
                          <LinkOff />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              </TextFieldConnector>
            ))}
        </Grid>
      ))}
      <Button variant="text" startIcon={<Add />} onClick={addAnswer}>
        Add Answer
      </Button>
    </Grid>
  );
};

const StyledQuestionContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.gray}`,
  padding: "6px",
  borderRadius: theme.shape.borderRadius,
  marginBottom: "4px",
}));

export default CO2PlansMetaItemCreation;
