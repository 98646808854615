import React, { useEffect } from "react";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { styled } from "@mui/material";
import { Container, Grid } from "@mui/material";
import { useAlertContext } from "../../../components/Base";
import { useServicebook } from "../../../actions/servicebook.actions";
import Craftsmen from "../../../assets/img/craftsmen.jpg";
import { useBuilding } from "../../../actions/building.actions";
import TendersList from "./components/TendersList";

export const ServiceBookBanner = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${Craftsmen})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPositionY: 0,
  width: "100%",
  margin: "0 0 20px",
  minHeight: 200,
  position: "relative",

  [theme.breakpoints.up("sm")]: {
    marginTop: -20,
    minHeight: 220,
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 300,
  },
}));

export enum StepsToShow {
  SelectReport = 1,
  ReportDetails = 2,
  ChooseCrafstmen = 3,
  Tasks = 4,
}

const BuildingTenders = () => {
  const [{ error, errorSeverity, loading, tenders, updating }, { getTenders, clearError }] = useServicebook();
  const { showAlert } = useAlertContext();
  const [{ building }] = useBuilding();

  useEffect(() => {
    const getAsync = async () => {
      if (building) {
        getTenders(building.Id);
      }
    };
    getAsync();
    return () => {
      /* */
    };
  }, [building]);

  useEffect(() => {
    if (error) {
      showAlert({
        text: error,
        severity: errorSeverity || "error",
        duration: 5000,
      });
      clearError();
    }
  }, [error]);

  return (
    <>
      <LoadingOverlay loading={loading || updating} />
      <Container maxWidth="md">
        <TendersList tenders={tenders} />
        {/* {report && (
            <ConditionDetailsStep
              report={report}
              unitType={building?.MainBuilding.Type}
              taskLists={offers}
              customerState={customerState}
              setItemsToFix={handleSetItemsToFix}
            />
          )} */}
      </Container>
    </>
  );
};

export default BuildingTenders;
