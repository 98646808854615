import React from "react";
import { Typography, Grid, Card, Box, useMediaQuery, useTheme, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddCircleOutline } from "@mui/icons-material";
import { Tender } from "../../../../definitions/model/ServiceBook";
import TenderCard from "./TenderCard";
import { useNavigate } from "react-router-dom";

const AddCircleOutlineStyled = styled(AddCircleOutline)(({ theme }) => ({
  fontSize: "6rem",
  width: "6rem",
  height: "6rem",
  transition: "color 0.3s",
  color: theme.palette.grey[500],
}));

const CardStyled = styled(Card)(({ theme }) => ({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

type ReportsListProps = {
  tenders: Tender[];
};

const TendersList = (props: ReportsListProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));

  const addTenderClick = () => {
    navigate("new");
  };

  const tenderDeatilsClick = (tenderId: string) => {
    navigate(tenderId);
  };

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2} alignContent="stretch" justifyContent={isSmDown ? "center" : "flex-start"}>
          <Grid item md={4} sm={6} xs={12}>
            <Box sx={{ height: "100%", minHeight: 300 }}>
              <CardStyled variant="outlined" onClick={addTenderClick}>
                <Grid container alignItems="center" justifyContent="center" direction="column" sx={{ height: "100%" }}>
                  <AddCircleOutlineStyled />
                  <Typography variant="h5">{t("ServiceBook.Tender.CreateNewTender")}</Typography>
                </Grid>
              </CardStyled>
            </Box>
          </Grid>
          {props.tenders.map((tender) => (
            <Grid item md={4} sm={6} xs={12} key={tender.Id}>
              <TenderCard tender={tender} onSelect={tenderDeatilsClick} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TendersList;
