import React, { useEffect } from "react";
import { MCard, TransferList } from "../../../../components/Base";
import { CardContent, Checkbox, Grid, IconButton, InputAdornment, styled, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Trans, useTranslation } from "react-i18next";
import { useBuilding } from "../../../../actions";
import { useParams } from "react-router-dom";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import { GroupMemberDto } from "../../../../definitions/model/Group";
import { GroupMemberRole } from "../../../../constants/enums";
import { useServicebook } from "../../../../actions/servicebook.actions";
import SingleTransferList from "../../../../components/Base/SingleTransferList";
import { Visibility } from "@mui/icons-material";
import TenderSectionsComponent from "./TenderSectionsComponent";
import { TenderSection } from "../../../../definitions/model/ServiceBook";
import { ModelDialog } from "../../../House/HouseModel/HouseModelPage";
import { convertToCurency } from "../../../../services/converter.service";
import { repeatSkeleton } from "../../../../components/Base/DetailsSkeleton";
import AddressHelper from "../../../../helpers/address-helper";
import { formatDateToString } from "../../../House/Map/utils";

const StyledForm = styled("form")(() => ({
  width: "100%",
}));

export type EditTenderFormData = {
  StandardPrice: number;
  CO2FootprintKilograms: number;
  PropertyId: string;
  PropertyName: string;
  //   Picture: string;
  Link_3DModel: string;
  DecisionMakers: string[];
  SigningRules: string;
  ConstructionAdvisor: string;
  PropertyAdministrator: string;
  Name: string;
  TypeSpecificConditions: string;
  Description: string;
  Area: number;
  DemolitionReportDate: string | null;
  BuildingPermitDate?: string | null;
  Sections: TenderSection[];
};

const EditTenderForm = () => {
  const { addressURL, tenderId } = useParams<{ addressURL: string; tenderId: string }>();
  const { t } = useTranslation("translation");
  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<EditTenderFormData>({
    defaultValues: {
      StandardPrice: 0,
      CO2FootprintKilograms: 0,
      Sections: [],
      Link_3DModel: "",
      DecisionMakers: [],
      BuildingPermitDate: null,
      DemolitionReportDate: null,
    },
  });
  const [loading, setLoading] = React.useState(false);
  const [open3dModel, setOpen3dModel] = React.useState(false);
  const [selectedDecisionMakers, setSelectedDecisionMakers] = React.useState<Array<GroupMemberDto>>([]);

  const [{ building, groupMembers }, { getBuilding, getBuildingMembers }] = useBuilding();
  const [, serviceBookActions] = useServicebook();

  useEffect(() => {
    register("ConstructionAdvisor", { required: true });
    register("PropertyAdministrator", { required: true });

    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    const fetchTenderDetails = async () => {
      try {
        setLoading(true);
        let members = groupMembers?.Members || [];
        if (addressURL) {
          const addressParts = addressURL.split("_");
          const id = addressParts[addressParts.length - 1];
          if (building?.Id !== id) {
            await getBuilding(id);
          }
          if (!groupMembers || groupMembers.BuildingId !== id) {
            const groupMembersDto = await getBuildingMembers(id);
            members = groupMembersDto.Members;
          }
        }
        if (tenderId) {
          const tender = await serviceBookActions.getTenderDetails(tenderId);
          if (tender) {
            setValue("StandardPrice", tender.StandardPrice);
            setValue("CO2FootprintKilograms", tender.CO2FootprintKilograms);
            setValue("PropertyId", tender.PropertyId);
            setValue("PropertyName", tender.PropertyName);
            setValue("DecisionMakers", tender.DecisionMakers);
            setSelectedDecisionMakers(members.filter((m) => tender.DecisionMakers.includes(m.UserId)) || []);
            setValue("SigningRules", tender.SigningRules);
            setValue("ConstructionAdvisor", tender.ConstructionAdvisor);
            setValue("PropertyAdministrator", tender.PropertyAdministrator);
            setValue("Name", tender.Name);
            setValue("TypeSpecificConditions", tender.TypeSpecificConditions);
            setValue("Description", tender.Description);
            setValue("Area", tender.Area);
            setValue("Link_3DModel", tender.Link_3DModel);
            setValue("DemolitionReportDate", formatDateToString(tender.DemolitionReportDate));
            setValue("BuildingPermitDate", formatDateToString(tender.BuildingPermitDate));
            setValue("Sections", tender.Sections);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTenderDetails();
  }, [addressURL, tenderId]);

  useEffect(() => {
    if (building) {
      setValue("PropertyId", building.Id);
      setValue("PropertyName", AddressHelper.GetAutocompleteInput(building.Address), { shouldValidate: true });
    }
  }, [building]);

  const save = async (data: EditTenderFormData) => {
    try {
      setLoading(true);
      const { price, co2 } = getPriseAndCO2();
      setValue("StandardPrice", price);
      setValue("CO2FootprintKilograms", co2);
      if (tenderId) {
        await serviceBookActions.updateTender({
          TenderId: tenderId,
          ...data,
          Picture: "",
          StandardPrice: price,
          CO2FootprintKilograms: co2,
          DemolitionReportDate: data.DemolitionReportDate ? new Date(data.DemolitionReportDate) : null,
          BuildingPermitDate: data.BuildingPermitDate ? new Date(data.BuildingPermitDate) : null,
        });
        return;
      }

      await serviceBookActions.createTender({
        ...data,
        Picture: "", // Replace with actual Picture URL or data
        StandardPrice: price,
        CO2FootprintKilograms: co2,
        DemolitionReportDate: data.DemolitionReportDate ? new Date(data.DemolitionReportDate) : null,
        BuildingPermitDate: data.BuildingPermitDate ? new Date(data.BuildingPermitDate) : null,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getPriseAndCO2 = () => {
    let price = 0;
    let co2 = 0;

    const sections = getValues("Sections");
    sections.forEach((section) => {
      const items = section.Items || [];
      items.forEach((item) => {
        price += (item.PricePerItem || 0) * (item.Quantity || 0) + (item.WorkHours || 0) * (item.WorkHourPrice || 0);
        co2 += (item.CO2EmissionPerItem || 0) * (item.Quantity || 0);
      });
    });

    return { price, co2 };
  };

  return (
    <>
      <MCard
        type="main"
        title={tenderId ? t("ServiceBook.Tender.EditTender") : t("ServiceBook.Tender.CreateNewTender")}
        sx={{ width: "inherit" }}
      >
        <CardContent>
          <StyledForm onSubmit={handleSubmit(save)}>
            {loading && <>{repeatSkeleton({ width: "100%", height: 80 }, 10)}</>}
            {!loading && (
              <Grid container spacing={1} mb={1}>
                <Grid item xs={12}>
                  <TextField
                    label={t("ServiceBook.Tender.StandardPrice")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    disabled
                    value={convertToCurency(getValues("StandardPrice"), ".")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={<Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2FootprintKilograms" />}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    disabled
                    value={convertToCurency(getValues("CO2FootprintKilograms"), ".")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("DemolitionReportDate")}>
                    <TextField
                      label={t("ServiceBook.Tender.DemolitionReportDate")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      type="date"
                      error={!!errors.DemolitionReportDate}
                      disabled={watch("DemolitionReportDate") === null}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Checkbox
                              checked={watch("DemolitionReportDate") !== null}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const today = new Date();
                                  setValue("DemolitionReportDate", formatDateToString(today));
                                  return;
                                }
                                setValue("DemolitionReportDate", null);
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("BuildingPermitDate")}>
                    <TextField
                      label={t("ServiceBook.Tender.BuildingPermitDate")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      type="date"
                      error={!!errors.BuildingPermitDate}
                      disabled={watch("BuildingPermitDate") === null}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Checkbox
                              checked={watch("BuildingPermitDate") !== null}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const today = new Date();
                                  setValue("BuildingPermitDate", formatDateToString(today));
                                  return;
                                }
                                setValue("BuildingPermitDate", null);
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("PropertyName", { required: true })}>
                    <TextField
                      label={t("ServiceBook.Tender.Property")}
                      InputLabelProps={{ shrink: !!getValues("PropertyName") }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.PropertyName}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("Link_3DModel")}>
                    <TextField
                      label={t("ServiceBook.Tender.3DModelLink")}
                      InputLabelProps={{ shrink: !!getValues("Link_3DModel") }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.Link_3DModel}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setOpen3dModel(true)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              <Visibility />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TransferList
                    options={groupMembers?.Members || []}
                    selected={selectedDecisionMakers}
                    fieldLabel={t("ServiceBook.Tender.DecisionMakers")}
                    placeholder={t("ServiceBook.Tender.DecisionMakers")}
                    onChanges={(s) => {
                      setValue(
                        "DecisionMakers",
                        s.map((o) => o.UserId)
                      );
                    }}
                    getOptionLabel={(o: GroupMemberDto) => `${o.Name} / ${GroupMemberRole[o.Role]}`}
                    getOptionSelected={(s, o) => s.UserId === o.UserId}
                    error={!!errors.DecisionMakers}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("SigningRules", { required: true })}>
                    <TextField
                      label={t("ServiceBook.Tender.SigningRules")}
                      InputLabelProps={{ shrink: !!getValues("SigningRules") }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.SigningRules}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <SingleTransferList
                    disabled={loading}
                    options={groupMembers?.Members || []}
                    selected={
                      groupMembers?.Members.filter((m) => m.UserId === getValues("ConstructionAdvisor"))[0] ?? null
                    }
                    fieldLabel={t("ServiceBook.Tender.ConstructionAdvisor")}
                    placeholder={t("ServiceBook.Tender.ConstructionAdvisor")}
                    onChanges={(selected) => {
                      setValue("ConstructionAdvisor", selected?.UserId ?? "");
                    }}
                    getOptionLabel={(o) => (o ? `${o.Name} / ${GroupMemberRole[o.Role]}` : "")}
                    getOptionSelected={(s, o) => s?.UserId === o?.UserId}
                    error={!!errors.ConstructionAdvisor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SingleTransferList
                    disabled={loading}
                    options={groupMembers?.Members || []}
                    selected={
                      groupMembers?.Members.filter((m) => m.UserId === getValues("PropertyAdministrator"))[0] ?? null
                    }
                    fieldLabel={t("ServiceBook.Tender.PropertyAdministrator")}
                    placeholder={t("ServiceBook.Tender.PropertyAdministrator")}
                    onChanges={(selected) => {
                      setValue("PropertyAdministrator", selected?.UserId ?? "");
                    }}
                    getOptionLabel={(o) => (o ? `${o.Name} / ${GroupMemberRole[o.Role]}` : "")}
                    getOptionSelected={(s, o) => s?.UserId === o?.UserId}
                    error={!!errors.PropertyAdministrator}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("Name", { required: true })}>
                    <TextField
                      label={t("ServiceBook.Tender.Name")}
                      InputLabelProps={{ shrink: !!getValues("Name") }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.Name}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("TypeSpecificConditions", { required: true })}>
                    <TextField
                      label={t("ServiceBook.Tender.TypeSpecificConditions")}
                      InputLabelProps={{ shrink: !!getValues("TypeSpecificConditions") }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.TypeSpecificConditions}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("Description", { required: true })}>
                    <TextField
                      label={t("ServiceBook.Tender.Description")}
                      InputLabelProps={{ shrink: !!getValues("Description") }}
                      rows={3}
                      fullWidth
                      variant="outlined"
                      error={!!errors.Description}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldConnector register={register("Area", { required: true })}>
                    <TextField
                      label={t("ServiceBook.Tender.Area")}
                      InputLabelProps={{ shrink: !!getValues("Area") }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.Area}
                    />
                  </TextFieldConnector>
                </Grid>
                {tenderId && (
                  <Grid item xs={12}>
                    <TenderSectionsComponent control={control} register={register} errors={errors} />
                  </Grid>
                )}
              </Grid>
            )}
            <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
              {t("General.Buttons.Save")}
            </LoadingButton>
          </StyledForm>
        </CardContent>
      </MCard>
      <ModelDialog open={open3dModel} handleClose={() => setOpen3dModel(false)} modelUrl={getValues("Link_3DModel")} />
    </>
  );
};

export default EditTenderForm;
