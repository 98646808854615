import { Reducer } from "redux";
import { AppAction } from "../definitions/Action";
import {
  ServiceBookReport,
  CraftsmanTaskList,
  ServiceBookReportPreview,
  CustomerReportState,
  Tender,
} from "../definitions/model/ServiceBook";
import { ServiceBookActions } from "../constants/servicebook.actiontypes";
import { ServiceBookItemToFix } from "../definitions/model/ServiceBook";

export interface ServiceBookStoreState {
  //  extends ServiceBookReport
  loading: boolean;
  updating: boolean;
  reportPreviewsLoaded: boolean;
  error: string | null;
  errorSeverity: "success" | "info" | "warning" | "error" | null;
  count: number;
  reportPreviews: ServiceBookReportPreview[];
  currentReport: ServiceBookReport | null;
  tenders: Tender[];
  itemsToFix: ServiceBookItemToFix[];
  tasks: CraftsmanTaskList[];
  //Temporary solution, until someone comes up with a UI idea (#562)
  offers: CraftsmanTaskList[];
  customerState: CustomerReportState | undefined;
}

export const initialState: ServiceBookStoreState = {
  //common state data
  loading: false,
  updating: false,
  reportPreviewsLoaded: false,
  error: null,
  errorSeverity: null,
  count: 0,
  reportPreviews: [],
  //current report data
  currentReport: null,
  tenders: [],
  itemsToFix: [],
  tasks: [],
  offers: [],
  customerState: undefined,
};

const serviceBookReducer: Reducer<ServiceBookStoreState, AppAction> = (
  state: ServiceBookStoreState = initialState,
  action: AppAction
): ServiceBookStoreState => {
  switch (action.type) {
    case ServiceBookActions.GET_REPORT:
    case ServiceBookActions.GET_REPORTS:
    case ServiceBookActions.GET_UNIT_TASKS:
    case ServiceBookActions.GET_UNIT_OFFERS:
    case ServiceBookActions.SEND_OFFER_REQUEST:
    case ServiceBookActions.GET_TENDERS:
      return { ...state, loading: true };
    case ServiceBookActions.ACCEPT_OFFER:
    case ServiceBookActions.REJECT_OFFER:
      return { ...state, updating: true };
    case ServiceBookActions.GET_REPORT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        updating: false,
        currentReport: action.payload,
      };
    case ServiceBookActions.GET_REPORTS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        updating: false,
        reportPreviewsLoaded: true,
        reportPreviews: action.payload,
      };
    case ServiceBookActions.SEND_OFFER_REQUEST_SUCCEEDED:
      return {
        ...state,
        loading: false,
        // error:
        //   "Du vil se tilbud på fanen Tilbud, når de er godkendt af håndværkere.",
        // errorSeverity: "success",
      };
    case ServiceBookActions.GET_UNIT_TASKS_SUCCEEDED:
      return { ...state, loading: false, tasks: action.payload };
    case ServiceBookActions.GET_UNIT_OFFERS_SUCCEEDED:
      return { ...state, loading: false, offers: action.payload };
    case ServiceBookActions.ACCEPT_OFFER_SUCCEEDED:
      return {
        ...state,
        updating: false,
        error: "Opgave oprettet. Du kan se status på Håndværkeropgaver siden.",
        errorSeverity: "success",
      };
    case ServiceBookActions.REJECT_OFFER_SUCCEEDED:
      return {
        ...state,
        updating: false,
        currentReport:
          state.currentReport !== null
            ? {
                ...state.currentReport,
                Offers: state.currentReport.Offers.filter((offer) => offer.Id !== action.payload),
              }
            : null,
        error: "Tilbuddet afvises.",
        errorSeverity: "success",
      };
    case ServiceBookActions.SET_ITEMS_TO_FIX:
      return {
        ...state,
        itemsToFix: action.payload,
      };
    //case ServiceBookActions.SET_TASK_STATUS_SUCCEEDED:
    // return {
    //   ...state,
    //   tasks: state.tasks.map((taskList) =>
    //     taskList.Id === action.payload.craftsmanId
    //       ? {
    //           ...taskList,
    //           Tasks: taskList.Tasks.map((task) =>
    //             task.Id === action.payload.taskId
    //               ? { ...task, Status: action.payload.status }
    //               : task
    //           ),
    //         }
    //       : taskList
    //   ),
    // };
    case ServiceBookActions.SERVICEBOOK_ACTION_FAILED:
      return {
        ...state,
        loading: false,
        updating: false,
        error: action.payload,
      };
    case ServiceBookActions.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        errorSeverity: null,
      };
    case ServiceBookActions.SET_CUSTOMER_REPORT_STATE:
      return {
        ...state,
        customerState: action.payload,
      };
    case ServiceBookActions.GET_TENDERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tenders: action.payload.Results,
      };
    default:
      return state;
  }
};

export default serviceBookReducer;
