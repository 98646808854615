import { AppAction, AppActionThunk } from "../../definitions/Action";
import { WorkersActionTypes } from "./workers.actiontypes";
import WorkersService from "./workers.service";
import {
  LinkRequestState,
  CreateCustomWorker,
  WorkerCompany,
  CompanyAutocompleteItem,
  CustomWorkerRequest,
} from "../../definitions/model/Worker";
import { SearchResult } from "../../definitions/model/SearchResult";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { operationFailedActionGeneral, useAppDispatch } from "../../actions";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, WorkersActionTypes.WORKERS_OPERATION_FAILED);
};

export const getWorkers =
  (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) =>
  async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({
        type: WorkersActionTypes.GET_WORKERS,
      });
      const result = await WorkersService.getWorkers(customWorkerRequest, skip, take);
      const type =
        skip === 0 ? WorkersActionTypes.GET_WORKERS_SUCCEEDED : WorkersActionTypes.GET_WORKERS_ADDITIONAL_SUCCEEDED;

      dispatch({
        type: type,
        payload: result,
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const getWorker = (workerId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({
      type: WorkersActionTypes.GET_WORKER,
    });
    const result = await WorkersService.getWorker(workerId);

    dispatch({
      type: WorkersActionTypes.GET_WORKER_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error: any) {
    operationFailedAction(error.message || error.statusText ? error : "Der opstod en fejl under indlæsning af data");
  }
};

const getUnitWorkers = (unitId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({
      type: WorkersActionTypes.GET_UNIT_WORKERS,
    });
    const result = await WorkersService.getUnitWorkers(unitId);

    dispatch({
      type: WorkersActionTypes.GET_UNIT_WORKERS_SUCCEEDED,
      payload: result,
    });

    const workerIds = result.map((worker) => worker.Id);
    const ratings = await WorkersService.getDetailedRatings(workerIds);
    dispatch({
      type: WorkersActionTypes.GET_UNIT_WORKERS_RATINGS_SUCCEEDED,
      payload: ratings,
    });
    return result;
  } catch (error: any) {
    dispatch(
      operationFailedAction(error.message || error.statusText ? error : "Der opstod en fejl under indlæsning af data")
    );
  }
};

const getBuildingWorkers =
  (buildingId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({
        type: WorkersActionTypes.GET_BUILDING_WORKERS,
      });
      const result = await WorkersService.getBuildingWorkers(buildingId);

      dispatch({
        type: WorkersActionTypes.GET_BUILDING_WORKERS_SUCCEEDED,
        payload: result,
      });

      // const workerIds = result.map((worker) => worker.Id);
      // const ratings = await WorkersService.getDetailedRatings(workerIds);
      // dispatch({
      //   type: WorkersActionTypes.GET_UNIT_WORKERS_RATINGS_SUCCEEDED,
      //   payload: ratings,
      // });
      return result;
    } catch (error: any) {
      dispatch(
        operationFailedAction(error.message || error.statusText ? error : "Der opstod en fejl under indlæsning af data")
      );
    }
  };

export const linkWorkerToUnit =
  (workerId: string, unitId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({
        type: WorkersActionTypes.LINK_WORKER_TO_UNIT,
      });
      const result = await WorkersService.linkWorkerToUnit(workerId, unitId);

      if (!result.Id) {
        dispatch(operationFailedAction("Der opstod en fejl under indlæsning af data."));
      }

      dispatch({
        type: WorkersActionTypes.LINK_WORKER_TO_UNIT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const unlinkWorkerFromUnit =
  (workerId: string, unitId: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: WorkersActionTypes.UNLINK_WORKER_FROM_UNIT,
      });
      const result = await WorkersService.unlinkWorkerFromUnit(workerId, unitId);

      dispatch({
        type: WorkersActionTypes.UNLINK_WORKER_FROM_UNIT_SUCCEEDED,
        payload: result,
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const linkWorkerToBuilding =
  (workerId: string, buildingId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({
        type: WorkersActionTypes.LINK_WORKER_TO_BUILDING,
      });
      const result = await WorkersService.linkWorkerToBuilding(workerId, buildingId);

      if (!result.Id) {
        dispatch(operationFailedAction("Der opstod en fejl under indlæsning af data."));
      }

      dispatch({
        type: WorkersActionTypes.LINK_WORKER_TO_BUILDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const unlinkWorkerFromBuilding =
  (workerId: string, buildingId: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: WorkersActionTypes.UNLINK_WORKER_FROM_BUILDING,
      });
      const result = await WorkersService.unlinkWorkerFromBuilding(workerId, buildingId);

      dispatch({
        type: WorkersActionTypes.UNLINK_WORKER_FROM_BUILDING_SUCCEEDED,
        payload: result,
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const rateUnitWorker =
  (workerId: string, unitId: string, rating: number, comment: string | null): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await WorkersService.rateUnitWorker(workerId, unitId, rating, comment);

      dispatch({
        type: WorkersActionTypes.RATE_UNIT_WORKER_SUCCEEDED,
        payload: { workerId, rating, comment },
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const rateBuildingWorker =
  (workerId: string, buildingId: string, rating: number, comment: string | null): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await WorkersService.rateBuildingWorker(workerId, buildingId, rating, comment);

      dispatch({
        type: WorkersActionTypes.RATE_BUILDING_WORKER_SUCCEEDED,
        payload: { workerId, rating, comment },
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const getWorkerLinkRequests =
  (unitId: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const response = await WorkersService.getWorkerLinkRequests(unitId);

      dispatch({
        type: WorkersActionTypes.GET_WORKER_LINK_REQUEST_SUCCEEDED,
        payload: response.Results,
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const processWorkerLinkRequest =
  (workerId: string, unitId: string, state: LinkRequestState): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await WorkersService.processWorkerLinkRequest(workerId, unitId, state);

      dispatch({
        type: WorkersActionTypes.PROCESS_WORKER_LINK_REQUEST_SUCCEEDED,
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

export const createWorker =
  (worker: CreateCustomWorker): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({ type: WorkersActionTypes.CREATE_WORKER });
      const result = await WorkersService.createWorker(worker);
      dispatch({
        type: WorkersActionTypes.CREATE_WORKER_SUCCEEDED,
        payload: result,
      });
    } catch (error: any) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getCompanies =
  (name: string): AppActionThunk<Promise<SearchResult<CompanyAutocompleteItem>>> =>
  async (dispatch) => {
    try {
      dispatch({ type: WorkersActionTypes.GET_COMPANIES });
      const result = await WorkersService.getCompanies(name);
      dispatch({
        type: WorkersActionTypes.GET_COMPANIES_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const createCompany =
  (company: WorkerCompany): AppActionThunk<Promise<CompanyAutocompleteItem>> =>
  async (dispatch) => {
    try {
      dispatch({ type: WorkersActionTypes.CREATE_COMPANY });
      const result = await WorkersService.createCompany(company);
      dispatch({ type: WorkersActionTypes.CREATE_COMPANY_SUCCEEDED });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const useWorkerState = () =>
  useSelector((state: ApplicationState) => ({
    ...state.workers,
    unitWorkerIds: state.workers.unitWorkers.map((worker) => worker.Id),
    unit: state.unit.unit,
  }));

const useWorkerActions = () => {
  const dispatch = useAppDispatch();
  return {
    getWorkers: (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) =>
      dispatch(getWorkers(customWorkerRequest, skip, take)),
    getUnitWorkers: (unitId: string) => dispatch(getUnitWorkers(unitId)),
    linkWorkerToUnit: (workerId: string, unitId: string) => dispatch(linkWorkerToUnit(workerId, unitId)),
    unlinkWorkerFromUnit: (workerId: string, unitId: string) => dispatch(unlinkWorkerFromUnit(workerId, unitId)),
    rateUnitWorker: (workerId: string, unitId: string, rating: number, comment: string | null) =>
      dispatch(rateUnitWorker(workerId, unitId, rating, comment)),
    rateBuildingWorker: (workerId: string, buildingId: string, rating: number, comment: string | null) =>
      dispatch(rateBuildingWorker(workerId, buildingId, rating, comment)),
    createWorker: (worker: CreateCustomWorker) => dispatch(createWorker(worker)),
    getCompanies: (name: string) => dispatch(getCompanies(name)),
    getWorkerLinkRequests: (unitId: string) => dispatch(getWorkerLinkRequests(unitId)),
    processWorkerLinkRequest: (workerId: string, unitId: string, state: LinkRequestState) =>
      dispatch(processWorkerLinkRequest(workerId, unitId, state)),
    getBuildingWorkers: (buildingId: string) => dispatch(getBuildingWorkers(buildingId)),
    linkWorkerToBuilding: (workerId: string, buildingId: string) =>
      dispatch(linkWorkerToBuilding(workerId, buildingId)),
    unlinkWorkerFromBuilding: (workerId: string, buildingId: string) =>
      dispatch(unlinkWorkerFromBuilding(workerId, buildingId)),
  };
};

export const useServicebookWorker = (): [ReturnType<typeof useWorkerState>, ReturnType<typeof useWorkerActions>] => {
  const state = useWorkerState();
  const actions = useWorkerActions();
  return [state, actions];
};
