import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider, Grid, Button } from "@mui/material";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import { isReactComponent } from ".";
import ReactPlayer from "react-player/lazy";
import { BrowserRouter } from "react-router-dom";
import { PreviousRouteProvider } from "../../../../routes/PreviouseRouteContext";
import { useTranslation } from "react-i18next";
import ContactMeBox from "../../../Base/ContactMeBox";
import { useLanding } from "../../../../actions/landing.actions";
import AdminService from "../../../../services/admin/admin.service";
import { SystemStringType } from "../../../../definitions/model/SystemString";

type VideoComponentProps = {
  src: string;
  contactButtonText?: string;
  textBefore?: string;
  textAfter?: string;
  linkTitle?: string;
  utmSource: string;
  leadButtonText?: string;
};

export const VideoComponent = ({
  src,
  contactButtonText,
  textBefore,
  textAfter,
  linkTitle,
  utmSource,
  leadButtonText,
}: VideoComponentProps): JSX.Element => {
  const [landingState, landingActions] = useLanding();
  const [isButtonShown, setIsButtonShown] = useState(false);
  const [isTextShown, setIsTextShown] = useState(false);
  const [isFormShown, setIsFormShown] = useState(false);

  const { t } = useTranslation("translation");

  const handlePause = () => {
    if (!isFormShown) {
      setIsButtonShown(true);
    }
  };

  const handleEnded = () => {
    if (!isFormShown) {
      setIsButtonShown(true);
    }
  };

  const handlePlay = () => {
    if (!isFormShown) {
      setIsButtonShown(false);
      setIsTextShown(false);
    }
  };

  const clickHandler = () => {
    setIsTextShown(true);
    setIsButtonShown(false);
  };

  const formShowHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsTextShown(false);
    setIsFormShown(true);
  };

  return (
    <Grid container sx={{ alignItems: "center", flexDirection: "column", marginTop: "15px", marginBottom: "15px" }}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "100%",
        }}
      >
        {src ? (
          <>
            <ReactPlayer
              url={src}
              controls
              width="50%"
              height="50%"
              onPause={handlePause}
              onEnded={handleEnded}
              onPlay={handlePlay}
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
            />
          </>
        ) : (
          <p>No video source</p>
        )}
      </Grid>
      <Grid item sx={{ marginTop: "30px" }}>
        {isButtonShown && !isTextShown && (
          <Button size="small" variant="contained" sx={{ minWidth: "150px" }} onClick={clickHandler}>
            {contactButtonText || t("ClimatePlan.PanelSubheadeLinkTextDesktop")}
          </Button>
        )}
        {isTextShown && !isButtonShown && (
          <span>
            {textBefore || ""}{" "}
            <a
              href="#"
              onClick={(e) => {
                formShowHandler(e);
              }}
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            >
              {linkTitle || t("ClimatePlan.PanelSubheadeLinkTextDesktop")}
            </a>{" "}
            {textAfter || ""}
          </span>
        )}
        {isFormShown && (
          <Grid item xs={12}>
            <ContactMeBox
              initialValues={{
                name: "",
                email: "",
                phone: "",
              }}
              utmSource={utmSource}
              contactButtonText={leadButtonText}
              addLead={landingActions.addLead}
              loading={landingState.loading}
              hideEmail={false}
              hideName={false}
              hidePhone={true}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions, theme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "VideoComponent";

  blockManager.add(GrapesTypes.VideoComponent, {
    label: "Video component",
    category: options.categoryLabel,
    content: `<div data-gjs-type="${GrapesTypes.VideoComponent}"></div>`,

    attributes: {
      class: "fa fa-youtube-play",
      "data-gjs-type": `${GrapesTypes.VideoComponent}`,
    },
  });
  const traits = [
    {
      type: "text",
      label: "Video Source",
      changeProp: true,
      name: "src",
    },
    {
      type: "text",
      label: "Button Title",
      changeProp: true,
      name: "buttonText",
      value: "buttonText",
    },
    {
      type: "text",
      label: "Text before link",
      changeProp: true,
      name: "textBeforeLink",
      placeholder: "Enter the text before link",
      value: "textBeforeLink",
    },
    {
      type: "text",
      label: "Text after link",
      changeProp: true,
      name: "textAfterLink",
      placeholder: "Enter the text after link",
      value: "textAfterLink",
    },
    {
      type: "text",
      label: "link title",
      changeProp: true,
      name: "linkTitle",
      placeholder: "Enter link title",
      value: "",
    },
    {
      type: "select",
      label: "Utm Source",
      name: "utmSource",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
    {
      label: "Lead Button title",
      name: "leadBtnText",
      changeProp: 1,
    },
  ];

  const getUtmSources = async () => {
    const utmSources = (await AdminService.getSystemStrings(SystemStringType.UtmSource)).map((utmSource) => ({
      id: utmSource.Value,
      name: utmSource.Value,
    }));

    return utmSources;
  };

  const subscriptions = traits.map((trait) => trait.name);

  domComponents.addType(GrapesTypes.VideoComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.VideoComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.VideoComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.VideoComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        buttonText: "buttonText",
        textBeforeLink: "textBeforeLink",
        textAfterLink: "textAfterLink",
        linkTitle: "",
        src: "",
        utmSource: "",
        leadBtnText: undefined,
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init(e: any) {
        getUtmSources().then((res) => {
          e.model.getTrait("utmSource").set("options", res);
        });

        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },

      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },

      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { src, buttonText, textBeforeLink, textAfterLink, linkTitle, utmSource, leadBtnText } =
          this.model.attributes;

        comps.reset();
        const compString = `<${compName} src=${src}  
                contactButtonText="${buttonText}"
                textBefore="${textBeforeLink}"
                textAfter="${textAfterLink}"
                buttonText="${buttonText}"
                linkTitle="${linkTitle}"
                utmSource="${utmSource}" 
                leadButtonText="${leadBtnText}"
                ></${compName}>`;
        comps.add(compString);

        const root = ReactDOM.createRoot(el);
        root.render(
          <StyledEngineProvider>
            <ThemeProvider theme={getOptionalTheme(theme)}>
              <Provider store={store}>
                <BrowserRouter>
                  <PreviousRouteProvider>
                    <VideoComponent
                      src={src}
                      contactButtonText={buttonText}
                      textBefore={textBeforeLink}
                      textAfter={textAfterLink}
                      linkTitle={linkTitle}
                      utmSource={utmSource}
                      leadButtonText={leadBtnText}
                    />
                  </PreviousRouteProvider>
                </BrowserRouter>
              </Provider>
            </ThemeProvider>
          </StyledEngineProvider>
        );
      },
    }),
  });
}
